import React from 'react';
import Header from '../component/template/Header';
import Beranda from '../component/home/home';
import Footer from '../component/template/Footer';

function Home() {
  return (
    <div>
      <Header />
      <div className='tinggi-content'>
        <Beranda />
      </div>
      <Footer />
    </div>
  );
}

export default Home;