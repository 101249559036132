import React from 'react';
import Forgot from '../component/auth/lupaPassword';

function LupaPassword() {
  return (
      <div className='bg-auth'>
        <video className="bg-video" src={require(`../assets/mp4/bg.mp4`)} muted loop autoPlay={"autoplay"} preload="auto" type="video/mp4" />
        <Forgot />
      </div>
    );
  }
  
  export default LupaPassword;