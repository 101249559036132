import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import './profile.css';
import Foto from '../../assets/image/avatar.png';

function Profile() {
  const { user_id } = useParams();
  const [username, setUsername] = useState('')
  const [nama_lengkap, setNamaLengkap] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [user_group_id, setUserGroup] = useState('')
  const [hak_akses, setHakAkses] = useState('')
  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const response = await axios.get(`/user/${user_id}`);
    setUsername(response.data.username);
    setNamaLengkap(response.data.nama_lengkap);
    setEmail(response.data.email);
    setPassword(response.data.password);
    setUserGroup(response.data.user_group_id);
    setHakAkses(response.data.hak_akses);
  }

  const Edit = async (e) => {
    e.preventDefault();
    await axios.put(`/user/${user_id}`, {
      username: username,
      nama_lengkap: nama_lengkap,
      email: email,
      password: password,
      user_group_id: user_group_id,
      hak_akses: hak_akses
    });
    getUser();
    handleCloseEdit();
  }

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  return (
    <Container className="d-flex justify-content-center container-profile">
      <Row>
        <Col sm={12}>
          <Card className="card-profile">
            <Card.Body>
              <div className='img-profile d-flex justify-content-center'>
                <img src={Foto} className="image-profile" alt="avatar" />
              </div>
              <h3 className="text-center">{username}</h3>
              <p className="text-muted text-center">{hak_akses}</p>
              <ul className="list-group list-group-unbordered mb-3">
                <li className="list-group-item">
                  <b>Nama Lengkap</b> <p className="float-end">{nama_lengkap}</p>
                </li>
                <li className="list-group-item">
                  <b>E-Mail</b> <p className="float-end">{email}</p>
                </li>
                <li className="list-group-item">
                  <b>Username</b> <p className="float-end">{username}</p>
                </li>
                <li className="list-group-item">
                  <b>Password</b> <p className="float-end">********</p>
                </li>
                <li className="list-group-item">
                  <b>Hak Akses</b> <p className="float-end">{hak_akses}</p>
                </li>
              </ul>
              <Button onClick={handleShowEdit} variant="btn btn-primary" className="btn-profile float-end" data-toggle="modal" data-target="#edit">Edit</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showEdit} onHide={handleCloseEdit} animation={true} className="modal-crud">
        <Form onSubmit={Edit} className="form-box">
          <Modal.Header className="header-modal">
            <Modal.Title>
              <span className="crud-icon"><i className="fas fa-user-cog" /></span>
              <span className="crud-judul"> Ubah</span><span className="crud-header"> Data Diri</span>
            </Modal.Title>
            <Button onClick={handleCloseEdit} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
          </Modal.Header>
          <Modal.Body className="form-crud">
            <Form.Group>
              <Form.Label className="crud-label">Nama Lengkap</Form.Label>
              <Form.Control type="text" placeholder='Nama Lengkap' value={nama_lengkap} onChange={(e) => setNamaLengkap(e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label className="crud-label">Username</Form.Label>
              <Form.Control type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label className="crud-label">Email</Form.Label>
              <Form.Control type="text" placeholder='Example@example.com' value={email} onChange={(e) => setEmail(e.target.value)} required />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button onClick={handleCloseEdit} className="btn btn-danger">Batal</Button>
            <Button variant="btn btn-primary" type="submit">Simpan</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
}

export default Profile;