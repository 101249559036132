import { Component } from 'react';

var hari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
var bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']

// menampilkan jam saat ini
export class Jam extends Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date() };
  }
  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  tick() {
    this.setState({
      date: new Date()
    });
  }
  render() {
    return this.state.date.toLocaleTimeString('en-US', { hour12: false })
  }
}

// menampilkan tanggal saat ini
export class Tanggal extends Component {
  constructor(props) {
    super(props);
    let tanggal = new Date().getDate();
    let bulann = new Date().getMonth();
    let tahun = new Date().getFullYear();
    let Format = tanggal + ' ' + bulan[bulann] + ' ' + tahun
    this.state = { date: Format };
  }
  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  tick() {
    let tanggal = new Date().getDate();
    let bulann = new Date().getMonth();
    let tahun = new Date().getFullYear();
    let Format = tanggal + ' ' + bulan[bulann] + ' ' + tahun
    this.setState({
      date: Format
    });
  }
  render() {
    return this.state.date
  }
}

// menampilkan hari saat ini
export class Hari extends Component {
  constructor(props) {
    super(props);
    var cariHari = new Date().getDay();
    var formatHari = hari[cariHari];
    this.state = { date: formatHari };
  }
  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  tick() {
    var cariHari = new Date().getDay();
    var formatHari = hari[cariHari];
    this.setState({
      date: formatHari
    });
  }
  render() {
    return this.state.date
  }
}

// menampilkan gambar waktu saat ini
export class Gambar extends Component {
  constructor(props) {
    super(props);
    var getJam = new Date().getHours();
    this.state = { jam: getJam };
  }
  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 60000);
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  tick() {
    var getJam = new Date().getHours();
    this.setState({
      jam: getJam
    });
  }
  render() {
    if (this.state.jam >= 5 && this.state.jam < 9) {
      return <img src={require(`../../assets/icon/pagi.png`)} style={{ width: '80px' }} alt="jadwal" />
    } else if (this.state.jam >= 9 && this.state.jam < 15) {
      return <img src={require(`../../assets/icon/siang.png`)} style={{ width: '80px' }} alt="jadwal" />
    } else if (this.state.jam >= 15 && this.state.jam < 19) {
      return <img src={require(`../../assets/icon/sore.png`)} style={{ width: '80px' }} alt="jadwal" />
    } else {
      return <img src={require(`../../assets/icon/malam.png`)} style={{ width: '80px' }} alt="jadwal" />
    }
  }
}

// menampilkan tanggal saat ini dengan format dd-mm-yyyy
export class Tgl extends Component {
  constructor(props) {
    super(props);
    let today = new Date();
    let DD = String(today.getDate()).padStart(2, '0');
    let MM = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let YYYY = today.getFullYear();
    let hh = today.getHours();
    let mm = today.getMinutes();
    let ss = today.getSeconds();
    let Format = DD + '-' + MM + '-' + YYYY + ' ' + hh + ':' + mm + ':' + ss;
    this.state = { date: Format };
  }
  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  tick() {
    let today = new Date();
    let DD = String(today.getDate()).padStart(2, '0');
    let MM = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let YYYY = today.getFullYear();
    let hh = today.getHours();
    let mm = today.getMinutes();
    let ss = today.getSeconds();
    let Format = DD + '-' + MM + '-' + YYYY + ' ' + hh + ':' + mm + ':' + ss;
    this.setState({
      date: Format
    });
  }
  render() {
    return this.state.date
  }
}