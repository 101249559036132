import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Jam, Tanggal, Gambar, Hari } from '../template/waktu';
import './display.css';

function Display() {
  const { halte_id } = useParams();
  const [Halte, setHalte] = useState([]);
  const [Bus, setBus] = useState([]);
  var arrayBus = [];
  const direktori = process.env.REACT_APP_FILE_URL;
  useEffect(() => {
    getHalteById();
    connect_socket();
    getBus();
  }, []);

  // dapatkan data halte berdasarkan id
  const getHalteById = async () => {
    const response = await axios.get(`/halte/${halte_id}`);
    setHalte(response.data);
  }

  // dapatkan data bus
  const getBus = async () => {
    const response = await axios.get('/bus');
    setBus(response.data);
    arrayBus = response.data;
  }

  // koneksi websocket
  const connect_socket = () => {
    let sc = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
    sc.onmessage = function (ev) {
      console.log('ws message');
      try {
        var d = JSON.parse(ev.data);
        let cariBus = arrayBus.findIndex(dataBus => dataBus.bus_id === d.bus_id);
        console.log(cariBus);

        // jika data bus tidak kosong
        if (cariBus !== -1) {
          arrayBus[cariBus] = d;
          arrayBus = arrayBus.sort((a, b) => a.jarak - b.jarak);
          setBus(arrayBus);
        }
      } catch (e) {
        console.log("coba lagi");
      }
    }
    sc.onopen = function (ev) {
      console.log('ws openned');
    }
    sc.onerror = function (ev) {
      console.log('ws error');
      console.log(ev);
      sc.close();
    }
    sc.onclose = function (ev) {
      console.log('ws closed');
      console.log(ev);
      setTimeout(function () {
        connect_socket();
      }, 1000);
    }
  }

  return (
    <div className='container'>
      <div className="card bg-display">
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-6'>
              <div className="halte-display">
                <img src={require('../../assets/image/display-halte.png')} style={{ width: '100px' }} alt="halte" />
              </div>
              <div className="btn_wrapper">
                <div className="btn_col color"><h3><b>{Halte.nama}</b></h3></div>
              </div>
              <div className='row'>
                <div className='col-2'>
                  <Gambar />
                </div>
                <div className='col-10'>
                  <h3 className="display-text"><Hari />,</h3>
                  <h3 className="display-text"><Tanggal /></h3>
                </div>
                <h1 className="display-text"><Jam /></h1>
              </div>
            </div>
            <div className="col-sm-6 logo-display">
              <div className='d-flex justify-content-end'>
                <img src={require('../../assets/image/dishub.png')} alt='dishub' style={{ height: '100px' }} />
                {/* <img src={require('../../assets/image/ptis.png')} alt='ptis' style={{ height: '100px' }} /> */}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-sm-12">
              <h1 className='text-center text-jadwal'><b>JADWAL KEDATANGAN BUS</b></h1>
              <table className="table table-sm text-jadwal">
                <thead>
                  <tr>
                    <th>Bus</th>
                    <th>Dari</th>
                    <th>Tujuan</th>
                    <th>Estimasi Kedatangan</th>
                  </tr>
                </thead>
                <tbody>
                  {Bus.slice(0, 5).map((data) => {
                    return (
                      <tr key={data.bus_id}>
                        <td><img src={require(`../../assets/icon/bus.png`)} style={{ width: '60px' }} alt="jadwal" />
                          {data.nama}<br />
                          {data.nopol}
                        </td>
                        <td>{data.dari}</td>
                        <td>{data.tujuan}</td>
                        <td>
                          {data.waktu} <br />
                          {data.jarak}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='row'>
            <video src={direktori + Halte.file} controls muted loop autoPlay={"autoplay"} preload="auto" className="display" type="video/mp4" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Display;