import React from 'react';
import '../../assets/css-js/style.css';
import '../../assets/css-js/template.css';
import Footer from '../template/Footer';
import Kemhub from '../../assets/image/kemhub.png';
import Playstore from '../../assets/image/google-play-badge.svg';
import Appstore from '../../assets/image/app-store-badge.svg';
import Bus from '../../assets/image/gambar_keren.png';
import LayananMurah from '../../assets/image/layanan-murah.png';
import ApkNaik from '../../assets/image/apk-naik.png';
import ApkMoney from '../../assets/image/apk-money.png';
import apkHalte from '../../assets/image/apk-halte.png';

function Beranda() {
  return (
    <div id="page-top">
      <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
        <div className="container">
          <a className="navbar-brand fw-bold" href="/">
            <img src={Kemhub} style={{ height: "50px" }} alt="logo-kemhub" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fas fa-bars" /> Menu
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
              <li className="nav-item"><a className="nav-link me-lg-3 link-aktif" href="/"><i className="fas fa-home" /> Beranda</a></li>
              <li className="nav-item"><a className="nav-link me-lg-3" href="/Rute"><i className="fas fa-route" /> Rute</a></li>
              <li className="nav-item"><a className="nav-link me-lg-3" href="/Kontak"><i className="fas fa-address-book" /> Kontak</a></li>
            </ul>
            <div className='dropdown-link'>
              <button className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0">
                <span className="d-flex align-items-center small">Download</span>
              </button>
              <div className="dropdown-os">
                <a href="https://play.google.com/store/apps/" target="_blank" rel="noopener noreferrer"><i className="fab fa-google-play" />&ensp;Android</a>
                <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-app-store-ios" />&ensp;IOS</a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div id="beranda">
        {/* Mashead header*/}
        <header className="masthead">
          <div className="container px-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6">
                {/* Mashead text and app badges*/}
                <div className="mb-5 mb-lg-0 text-center text-lg-start">
                  <h1 className="display-5 lh-1 mb-3">Public Transport Information System</h1>
                  <p className="lead fw-normal text-muted mb-5 rata-kanan-kiri">Untuk kemudahan akses penumpang melihat rute perjalanan, informasi jadwal keberangkatan dan kedatangan Bus secara real time dapat melalui aplikasi PTIS yang bisa di unduh di playstore dan appstore.</p>
                  <div className="d-flex flex-column flex-lg-row align-items-center">
                    <a className="me-lg-3 mb-4 mb-lg-0" href="https://play.google.com/store/apps/" target="_blank" rel="noopener noreferrer"><img className="app-badge" src={Playstore} alt="..." /></a>
                    <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer"><img className="app-badge" src={Appstore} alt="..." /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* Masthead device mockup feature*/}
                <div className="masthead-device-mockup">
                  <svg className="circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient id="circleGradient" gradientTransform="rotate(45)">
                        <stop className="gradient-start-color" offset="0%" />
                        <stop className="gradient-end-color" offset="100%" />
                      </linearGradient>
                    </defs>
                    <circle cx={50} cy={50} r={50} /></svg>
                  <svg className="shape-1 d-none d-sm-block" viewBox="0 0 240.83 240.83" xmlns="http://www.w3.org/2000/svg">
                    <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(120.42 -49.88) rotate(45)" />
                    <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(-49.88 120.42) rotate(-45)" /></svg>
                  <svg className="shape-2 d-none d-sm-block" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx={50} cy={50} r={50} /></svg>
                  <div className="device-wrapper">
                    <div className="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
                      <div className="screen bg-black">
                        <img className="img-fluid" src={require(`../../assets/image/beranda.jpeg`)} alt="Bus" style={{ maxWidth: '100%', height: '100%', backgroundColor: 'white' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Quote/testimonial aside*/}
        <aside className="text-center bg-gradient-primary-to-secondary">
          <div className="container px-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-12">
                <div className="h2 fs-1 text-white mb-4">Layanan Kami</div>
              </div>
            </div>
            <div className="row gx-5 justify-content-center text-white">
              <div className="col-md-4">
                <img src={LayananMurah} style={{ height: "100px" }} alt="layanan-murah" />
                <h3>Hemat</h3>
                <p>Naik Bus memang paling HEMAT. Tidak perlu khawatir saldo kartu non tunai Anda 0, karena naik BUS GRATIS lho!</p>
              </div>
              <div className="col-md-4">
                <img src={require(`../../assets/image/apk-tracking.png`)} style={{ height: "100px" }} alt="layanan-hp" />
                <h3>Mudah</h3>
                <p>Tidak perlu khawatir ketinggalan BUS, cukup buka aplikasi dan langsung cek jadwal perjalanan Bus PTIS. MUDAH kan!</p>
              </div>
              <div className="col-md-4">
                <img src={ApkNaik} style={{ height: "100px" }} alt="layanan-naik" />
                <h3>Nyaman</h3>
                <p>Naik BUS PTIS dijamin Nyaman! Karena dilengkapi fasilitas yang bersih dengan layanan terbaik.</p>
              </div>
            </div>
          </div>
        </aside>
        {/* App features section*/}
        <section id="features">
          <div className="container px-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-8 order-lg-1 mb-5 mb-lg-0">
                <div className="container-fluid px-5">
                  <div className="row gx-5">
                    <div className="col-md-6 mb-5">
                      {/* Feature item*/}
                      <div className="text-center">
                        <img src={require(`../../assets/image/apk-tracking.png`)} style={{ height: "100px" }} alt="apk-hp" />
                        <h3 className="font-alt">Buka Aplikasi PTIS</h3>
                        <p className="text-muted mb-0">Klik rute perjalanan lalu pilih “Koridor” dan pilih “Info Jadwal” keberangkatan.</p>
                      </div>
                    </div>
                    <div className="col-md-6 mb-5">
                      {/* Feature item*/}
                      <div className="text-center">
                        <img src={apkHalte} style={{ height: "100px" }} alt="apk-halte" />
                        <h3 className="font-alt">Tunggu di Halte</h3>
                        <p className="text-muted mb-0">Tunggulah di halte terdekat yang tertera di aplikasi dari posisi Anda.</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-5 mb-md-0">
                      {/* Feature item*/}
                      <div className="text-center">
                        <img src={ApkMoney} style={{ height: "100px" }} alt="apk-money" />
                        <h3 className="font-alt">Siapkan Kartu Non Tunai</h3>
                        <p className="text-muted mb-0">Siapkan kartu non tunai (tap cash BNI, flazz BCA, e-money MANDIRI dan brizzi BRI). Dan PTIS segera datang menjemput Anda.</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* Feature item*/}
                      <div className="text-center">
                        <img src={ApkNaik} style={{ height: "100px" }} alt="apk-naik" />
                        <h3 className="font-alt">Naik dan Tempel Kartu</h3>
                        <p className="text-muted mb-0">Naik dan tempelkan kartu non tunai pada perangkat reader yang ada di dalam Bus.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-lg-0">
                {/* Features section device mockup*/}
                <div className="features-device-mockup">
                  <svg className="circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient id="circleGradient" gradientTransform="rotate(45)">
                        <stop className="gradient-start-color" offset="0%" />
                        <stop className="gradient-end-color" offset="100%" />
                      </linearGradient>
                    </defs>
                    <circle cx={50} cy={50} r={50} /></svg>
                  <svg className="shape-1 d-none d-sm-block" viewBox="0 0 240.83 240.83" xmlns="http://www.w3.org/2000/svg">
                    <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(120.42 -49.88) rotate(45)" />
                    <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(-49.88 120.42) rotate(-45)" /></svg>
                  <svg className="shape-2 d-none d-sm-block" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx={50} cy={50} r={50} /></svg>
                  <div className="device-wrapper">
                    <div className="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
                      <div className="screen bg-black">
                        <video src={require(`../../assets/mp4/apk.mp4`)} type="video/mp4" muted="muted" autoPlay loop style={{ maxWidth: '100%', height: '100%' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Basic features section*/}
        <section className="bg-gray">
          <div className="container px-5">
            <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
              <div className="col-12 col-lg-7">
                <h2 className="display-5 lh-1 mb-4">Public Transport Information System (PTIS)</h2>
                <p className="lead fw-normal text-muted mb-5 mb-lg-0 rata-kanan-kiri">PTIS berupa kendaraan Bus Sedang yang berkapasitas 40 penumpang dengan 20 tempat duduk dan Bus Besar yang berkapasitas 60 penumpang dengan 30 tempat duduk. Serta masing-masing terdapat 1 area untuk prioritas. PTIS dilengkapi dengan CCTV dan sensor alarm pengemudi adalah salah satu upaya untuk memberikan rasa aman bagi penumpang.</p>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="px-5 px-sm-0"><img className="img-fluid" src={Bus} alt="Bus" /></div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Beranda;