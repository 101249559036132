import React from 'react';
import Reset from '../component/auth/resetPassword';

function ResetPassword() {
  return (
      <div className='bg-auth'>
        <video className="bg-video" src={require(`../assets/mp4/bg.mp4`)} muted loop autoPlay={"autoplay"} preload="auto" type="video/mp4" />
        <Reset />
      </div>
    );
  }
  
  export default ResetPassword;