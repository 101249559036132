import React from 'react';
import '../../assets/css-js/style.css';
import '../../assets/css-js/template.css';
import Footer from '../template/Footer';
import Kemhub from '../../assets/image/kemhub.png';

function Kontak() {
  const cancelCourse = () => {
    document.getElementById("contactForm").reset();
  }
  return (
    <div id="page-top">
      <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
        <div className="container px-5">
          <a className="navbar-brand fw-bold" href="/">
            <img src={Kemhub} style={{ height: "50px" }} alt="logo-kemhub" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fas fa-bars" /> Menu
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
              <li className="nav-item"><a className="nav-link me-lg-3" href="/"><i className="fas fa-home" /> Beranda</a></li>
              <li className="nav-item"><a className="nav-link me-lg-3" href="/Rute"><i className="fas fa-route" /> Rute</a></li>
              <li className="nav-item"><a className="nav-link me-lg-3 link-aktif" href="/Kontak"><i className="fas fa-address-book" /> Kontak</a></li>
            </ul>
            <div className='dropdown-link'>
              <button className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0">
                <span className="d-flex align-items-center small">Download</span>
              </button>
              <div className="dropdown-os">
                <a href="https://play.google.com/store/apps/" target="_blank" rel="noopener noreferrer"><i className="fab fa-google-play" />&ensp;Android</a>
                <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-app-store-ios" />&ensp;IOS</a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div id="kontak" style={{ marginTop: "100px" }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='mt-5'>
                <h2 className='pb-3'>Kontak Kami</h2>
                <ul className="list-unstyled">
                  <li className="pb-2 h5">
                    <img src={require(`../../assets/image/telp.png`)} style={{ height: "30px" }} alt="..." />
                    <a className="text-decoration-none kontak py-1" href="tel:010-020-0340"> (027) 447727</a>
                  </li>
                  <li className="pb-2 h5">
                    <img src={require(`../../assets/image/wa.png`)} style={{ height: "30px" }} alt="..." />
                    <a className="text-decoration-none kontak py-1" target="_blank" rel="noopener noreferrer" href="https://www.whatsapp.com/"> 0812 8728 6358</a>
                  </li>
                  <li className="pb-2 h5">
                    <img src={require(`../../assets/image/gmail.png`)} style={{ height: "30px" }} alt="..." />
                    <a className="text-decoration-none kontak py-1" href="mailto:javis@javis.co.id"> javis@javis.co.id</a>
                  </li>
                  <li className="pb-2 h5">
                    <img src={require(`../../assets/image/jam.png`)} style={{ height: "30px" }} alt="..." />
                    <a className="text-decoration-none kontak py-1" href="mailto:javis@javis.co.id"> Senin-Jumat: 08:00 WIB - 16:00 WIB</a>
                  </li>
                  <li className="pb-2 h5 rata-kanan-kiri">
                    <img src={require(`../../assets/image/kantor.png`)} style={{ height: "30px" }} alt="..." />
                    <a className="text-decoration-none kontak py-1" target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps?q=Jl.+Elang+Jawa+No.12,+Karangsari,+Wedomartani,+Kec.+Ngemplak,+Kabupaten+Sleman,+Daerah+Istimewa+Yogyakarta+55584"> Jl. Elang Jawa No.12, Karangsari, Wedomartani, Kec. Ngemplak, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55584</a>
                  </li>
                </ul>
              </div>
              <div className='mt-xxl-2'>
                <h3>Ikuti Kami</h3>
                <ul className="list-inline footer-icons">
                  <li className="list-inline-item m-0 pe-2 h5">
                    <a className="kontak" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/javis.teknologi.1">
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li className="list-inline-item m-0 px-2 h5">
                    <a className="kontak" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/jogjavision/">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item m-0 px-2 h5">
                    <a className="kontak" target="_blank" rel="noopener noreferrer" href="https://www.twitter.com/">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item m-0 px-2 h5">
                    <a className="kontak" target="_blank" rel="noopener noreferrer" href="https://www.javis.co.id/">
                      <i className="fas fa-globe"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="feedbackModal" className='col-md-6' tabIndex={-1} aria-labelledby="feedbackModalLabel">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header bg-gradient-primary-to-secondary p-4">
                    <h5 className="modal-title font-alt text-white" id="feedbackModalLabel">Kirim Saran</h5>
                  </div>
                  <div className="modal-body border-0 p-4">
                    <form id="contactForm" onSubmit={cancelCourse}>
                      <div className="form-floating mb-3">
                        <input className="form-control" id="name" type="text" placeholder="Enter your name..." required />
                        <label htmlFor="name">Nama Lengkap</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input className="form-control" id="email" type="email" placeholder="name@example.com" required />
                        <label htmlFor="email">Alamat Email</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input className="form-control" id="phone" type="text" placeholder="(123) 456-7890" required />
                        <label htmlFor="phone">No Telp.</label>
                      </div>
                      <div className="form-floating mb-3">
                        <textarea className="form-control" id="message" type="text" placeholder="Enter your message here..." style={{ height: '10rem' }} required defaultValue={""} />
                        <label htmlFor="message">Saran</label>
                      </div>
                      <div className="d-grid"><button className="btn btn-primary rounded-pill btn-lg" id="submitButton" type="submit">Submit</button></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Kontak;