import React from 'react';
import Print from '../component/jadwal/printJadwal';

function PrintJadwal() {
  return (
      <div>
        <Print />
      </div>
  );
}

export default PrintJadwal;