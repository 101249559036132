import React from 'react';
import Logo from '../../assets/image/bus-tracking.png';

function Home() {
  return (
    <div id="dashboardNav">
      <header className="masthead">
        <div className="container">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <div className="mb-lg-0 text-center text-lg-start">
                <h1 className="display-5 lh-1 mb-3">Public Transport Information System</h1>
                <p className="lead fw-normal text-muted mb-5 rata-kanan-kiri">Untuk kemudahan akses penumpang melihat rute perjalanan, informasi jadwal keberangkatan dan kedatangan Bus secara real time dapat melalui aplikasi PTIS yang bisa di unduh di playstore dan appstore.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <svg className="circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient id="circleGradient" gradientTransform="rotate(45)">
                      <stop className="gradient-start-color" offset="0%" />
                      <stop className="gradient-end-color" offset="100%" />
                    </linearGradient>
                  </defs>
                  <circle cx={50} cy={50} r={50} /></svg>
                <svg className="shape-1 d-none d-sm-block" viewBox="0 0 240.83 240.83" xmlns="http://www.w3.org/2000/svg">
                  <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(120.42 -49.88) rotate(45)" />
                  <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(-49.88 120.42) rotate(-45)" /></svg>
                <svg className="shape-2 d-none d-sm-block" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx={50} cy={50} r={50} /></svg>
                <div className="device-wrapper">
                  <div className="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
                    <div className="screen bg-black">
                      <img className="img-fluid" src={require(`../../assets/image/beranda.jpeg`)} alt="Bus" style={{ maxWidth: '100%', height: '100%', backgroundColor: 'white' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <aside className="text-center bg-gray">
        <div className="container">
          <div className="row gx-5 justify-content-center">
            <div className="col-12">
              <div className="h2 fs-1 mb-4">Layanan Aplikasi</div>
            </div>
          </div>
          <div className="row gx-5 justify-content-center">
            <div className="col-auto mb-3">
              <div className="card" style={{ width: '11rem' }}>
                <img src={Logo} className="card-img-top" style={{ height: '7rem' }} alt="..." />
                <div className="card-body">
                  <h5 className="card-title mb-3">Tracking Bus</h5>
                  <a href="/Tracking" className="btn btn-primary">Lihat Detail</a>
                </div>
              </div>
            </div>
            <div className="col-auto mb-3">
              <div className="card" style={{ width: '11rem' }}>
                <img src={require(`../../assets/image/schedule.png`)} style={{ height: '7rem' }} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title mb-3">Lihat Jadwal</h5>
                  <a href="/Jadwal" className="btn btn-primary">Lihat Detail</a>
                </div>
              </div>
            </div>
            <div className="col-auto mb-3">
              <div className="card" style={{ width: '11rem' }}>
                <img src={require(`../../assets/image/apk-naik.png`)} style={{ height: '7rem' }} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title mb-3">Kelola Bus</h5>
                  <a href="/list-bus" className="btn btn-primary">Lihat Detail</a>
                </div>
              </div>
            </div>
            <div className="col-auto mb-3">
              <div className="card" style={{ width: '11rem' }}>
                <img src={require(`../../assets/image/apk-halte.png`)} style={{ height: '7rem' }} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title mb-3">Kelola Halte</h5>
                  <a href="list-halte" className="btn btn-primary">Lihat Detail</a>
                </div>
              </div>
            </div>
            <div className="col-auto mb-3">
              <div className="card" style={{ width: '11rem' }}>
                <img src={require(`../../assets/image/rute.png`)} style={{ height: '7rem' }} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title mb-3">Kelola Trayek</h5>
                  <a href="list-trayek" className="btn btn-primary">Lihat Detail</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
}

export default Home;