export const customizeMap = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];

export const ukuran = {
    position: 'relative',
    height: '100%',
    width: '100%'
  };

export const warna = ["#0000FF", "#00FFFF" ,"#FFD700", "#00FF00", "#964B00", "#FF00FF", "#FFC0CB", "#C0C0C0", "#FFFFFF", "	#BF00FF", "#2F4F4F", "#778899", "#FFF0F5", "#FFFFF0", "#A52A2A", "#D2691E", "#00BFFF", "#5F9EA0", "#7FFFD4", "#008080", "#808000", "#6A5ACD", "#8B008B", "#9932CC", "#9370DB", "#FF00FF", "#F0E68C", "#FF8C00", "#FF4500", "#FFA07A", "#8B0000", "#FFC0CB"]