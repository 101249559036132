import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { Input, InputAdornment, IconButton } from "@material-ui/core";
import Select from 'react-select';
import swal from 'sweetalert';
import axios from 'axios';

function ListUser() {
  const HakAkses = [
    { value: 'petugas', label: 'petugas' },
    { value: 'kepala', label: 'pengguna' },
    { value: 'admin', label: 'admin' }
  ]
  const [User, setUser] = useState([]);
  const [UserGroup, setGroupUser] = useState([]);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');
  useEffect(() => {
    getUser();
    getUserGroup();
  }, []);

  // dapatkan data user
  const getUser = async () => {
    const response = await axios.get(`/user?page=${currentPage}&limit=${limit}`);
    setUser(response.data[0]);
    setTotal(Math.ceil(response.data[1].jumlah / limit));
  }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/user/cari/?page=${currentPage}&limit=${limit}&key=${key}`);
      setUser(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    } else {
      const response = await axios.get(`/user?page=${currentPage}&limit=${limit}`);
      setUser(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    }
  }

  // handle pagination
  const Pagination = async (page) => {
    setCurrentPage(page);
    getUser();
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <li className={currentPage === i ? 'page-item active' : 'page-item'}><button className="page-link" onClick={() => Pagination(i)}>{i}</button></li>
    );
  }

  // dapatkan data group user 
  const getUserGroup = async () => {
    const response = await axios.get('/user_group');
    const options = response.data.map(d => ({
      "value": d.user_group_id,
      "label": d.nama
    }))
    setGroupUser(options);
  }
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [user_id, setUserId] = useState('')
  const [username, setUsername] = useState('')
  const [nama_lengkap, setNamaLengkap] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [user_group_id, setUserGroup] = useState('')
  const [hak_akses, setHakAkses] = useState('')

  // tambah user
  const Tambah = async (e) => {
    e.preventDefault();
    await axios.post('/user', {
      username: username,
      nama_lengkap: nama_lengkap,
      email: email,
      password: password,
      user_group_id: user_group_id,
      hak_akses: hak_akses,
      active: 1
    });
    getUser();
    handleCloseAdd();
  }

  // edit data user
  const Edit = async (e) => {
    e.preventDefault();
    await axios.put(`/user/${user_id}`, {
      username: username,
      nama_lengkap: nama_lengkap,
      email: email,
      password: password,
      user_group_id: user_group_id,
      hak_akses: hak_akses
    });
    getUser();
    handleCloseEdit();
  }

  // hapus data user
  const Hapus = (user_id) => {
    swal({
      title: "Apakah Anda yakin akan menghapus data ini ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.delete('/user/' + user_id)
          swal("Data Halte Sukses dihapus", {
            icon: "success",
          })
          getUser();
        } else {
          swal("Data gagal dihapus");
        }
      });
  }

  // handle modal tambah
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);

  // handle modal edit
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
    setUserId('');
    setUsername('');
    setNamaLengkap('');
    setEmail('');
    setPassword('');
    setUserGroup('');
    setHakAkses('');
  }
  const handleShowEdit = (data) => {
    setShowEdit(true);
    setUserId(data.user_id);
    setUsername(data.username);
    setNamaLengkap(data.nama_lengkap);
    setEmail(data.email);
    setPassword(data.password);
    setUserGroup(data.user_group_id);
    setHakAkses(data.hak_akses);
  }

  return (
    <div className="container-xl">
      <h1 className="teks-hitam">Daftar User</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Beranda</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Daftar User
          </li>
        </ol>
      </nav>
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="d-flex justify-content-between">
              <div>
                <Button onClick={handleShowAdd} className="btn btn-success" data-toggle="modal"><i className="fas fa-plus"></i> <span>Tambah User&ensp;</span></Button>
              </div>
              <div>
                <Form onSubmit={filter} className="d-flex justify-content-end">
                  <Form.Control style={{ width: '15rem' }} value={key} className="me-2" onChange={(e) => setKey(e.target.value)} type="text" placeholder="Pencarian" aria-label="Search" />
                  <Button className="btn btn-outline-success" type="submit">Cari</Button>
                </Form>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama User</th>
                <th>Username</th>
                <th>Email</th>
                <th>Hak Akses</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!!User && User ? (
                User.map((item, i) => (
                  <tr key={item.user_id}>
                    <td>{i + 1}</td>
                    <td>{item.nama_lengkap}</td>
                    <td>{item.username}</td>
                    <td>{item.email}</td>
                    <td>{item.hak_akses}</td>
                    <td>
                      <Button onClick={() => handleShowEdit(item)} className="edit" data-toggle="modal"><i className="fas fa-edit" data-toggle="tooltip"></i> Edit</Button>
                      <Button onClick={() => Hapus(item.user_id)} className="delete" data-toggle="modal"><i className="fas fa-trash" data-toggle="tooltip"></i> Hapus</Button>
                    </td>
                  </tr>
                ))) : (<div>Tidak ada data</div>)}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                <button className="page-link" aria-label="Previous" onClick={() => Pagination(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {pageNumbers}
              <li className={currentPage === Total ? 'page-item disabled' : 'page-item'}>
                <button className="page-link" aria-label="Next" onClick={() => Pagination(currentPage + 1)}>
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        {/* modal tambah user */}
        <Modal size="lg" show={showAdd} onHide={handleCloseAdd} animation={true} className="modal-crud">
          <Form onSubmit={Tambah} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-user-cog"></i></span>
                <span className="crud-judul"> Tambah</span><span className="crud-header"> User</span>
              </Modal.Title>
              <Button onClick={handleCloseAdd} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Nama Lengkap</Form.Label>
                <Form.Control type="text" placeholder='Nama Lengkap' value={nama_lengkap} onChange={(e) => setNamaLengkap(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Username</Form.Label>
                <Form.Control type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Email</Form.Label>
                <Form.Control type="text" placeholder='Example@example.com' value={email} onChange={(e) => setEmail(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Password</Form.Label>
                <Input className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  } />
              </Form.Group>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label className="crud-label">Hak Akses</Form.Label>
                    <Select options={HakAkses} onChange={(e) => setHakAkses(e.value)} />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label className="crud-label">User Group</Form.Label>
                    <Select options={UserGroup} onChange={(e) => setUserGroup(e.value)} />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseAdd} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* modal edit user */}
        <Modal size="lg" show={showEdit} onHide={handleCloseEdit} animation={true} className="modal-crud">
          <Form onSubmit={Edit} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-user-cog"></i></span>
                <span className="crud-judul"> EDIT</span><span className="crud-header"> USER</span>
              </Modal.Title>
              <Button onClick={handleCloseEdit} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Nama Lengkap</Form.Label>
                <Form.Control type="text" placeholder='Nama Lengkap' value={nama_lengkap} onChange={(e) => setNamaLengkap(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Username</Form.Label>
                <Form.Control type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Email</Form.Label>
                <Form.Control type="text" placeholder='Example@example.com' value={email} onChange={(e) => setEmail(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Password</Form.Label>
                <Input className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  } />
              </Form.Group>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label className="crud-label">Hak Akses</Form.Label>
                    <Select options={HakAkses} defaultValue={HakAkses.filter(({ value }) => value === hak_akses)} onChange={(e) => setHakAkses(e.value)} />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label className="crud-label">User Group</Form.Label>
                    <Select options={UserGroup} defaultValue={UserGroup.filter(({ value }) => value === user_group_id)} onChange={(e) => setUserGroup(e.value)} />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseEdit} className="btn btn-danger">Cancel</Button>
              <Button variant="btn btn-primary" type="submit">Submit</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default ListUser;