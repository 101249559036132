import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import swal from 'sweetalert';

function acakNama(length) {
  var result = '';
  var karakter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var panjangKarakter = karakter.length;
  for (var i = 0; i < length; i++) {
    result += karakter.charAt(Math.floor(Math.random() * panjangKarakter));
  }
  return result;
}

function ListDisplay() {
  const [Display, setDisplay] = useState([]);
  const [content_id, setContentId] = useState('');
  const [nama_content, setNamaContent] = useState('');
  const [file, setFile] = useState('');
  const [namaFile, setNamaFile] = useState('');
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');
  const [nama, setNama] = useState('');
  const direktori = process.env.REACT_APP_FILE_URL;
  useEffect(() => {
    getDisplay();
  }, []);

  // preview video
  const handleChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      var getFormat = file.name.split('.');
      var formatFile = '.' + getFormat[getFormat.length - 1];
      setFile(url);
      setNamaFile(file);
      setNama(Date.now() + acakNama(10) + formatFile)
    }
  };

  // ambil data konten display
  const getDisplay = async () => {
    const response = await axios.get(`/content?page=${currentPage}&limit=${limit}`);
    setDisplay(response.data[0]);
    setTotal(Math.ceil(response.data[1].jumlah / limit));
  }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/content/cari/?page=${currentPage}&limit=${limit}&key=${key}`);
      setDisplay(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    } else {
      const response = await axios.get(`/content?page=${currentPage}&limit=${limit}`);
      setDisplay(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    }
  }

  // tambah konten display
  const Tambah = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", namaFile, nama);
    await axios.post('/videoUpload', formData);
    await axios.post('/content', {
      nama_content: nama_content,
      file: nama
    });
    getDisplay();
    setShowAdd(false);
  }

  // edit konten display
  const Edit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", namaFile, nama);
    await axios.put(`/content/${content_id}`, {
      nama_content: nama_content,
      file: nama
    });
    getDisplay();
    setShowEdit(false);
  }

  // hapus konten display
  const Hapus = (display_id) => {
    swal({
      title: "Apakah Anda yakin akan menghapus data ini ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.delete('/content/' + display_id)
          swal("Data Display Sukses dihapus", {
            icon: "success",
          })
          getDisplay();
        } else {
          swal("Data tidak terhapus");
        }
      });
  }

  // modal tambah display
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);

  // modal edit display
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
    setContentId('');
    setNamaContent('');
    setFile('');
  }

  const handleShowEdit = (data) => {
    setShowEdit(true);
    setContentId(data.content_id);
    setNamaContent(data.nama_content);
    setFile(data.file);
  }

  const Pagination = async (page) => {
    setCurrentPage(page);
    getDisplay();
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <li className={currentPage === i ? 'page-item active' : 'page-item'}><button class="page-link" onClick={() => Pagination(i)}>{i}</button></li>
    );
  }

  return (
    <div className="container-xl">
      <h1 className="teks-hitam">Daftar Konten</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Beranda</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Daftar Konten
          </li>
        </ol>
      </nav>
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="d-flex justify-content-between">
              <div>
                <Button onClick={handleShowAdd} className="btn btn-success"><i className="fas fa-plus"></i> <span>Tambah Konten&ensp;</span></Button>
              </div>
              <div>
                <Form onSubmit={filter} className="d-flex justify-content-end">
                  <Form.Control style={{ width: '15rem' }} value={key} className="me-2" onChange={(e) => setKey(e.target.value)} type="text" placeholder="Pencarian" aria-label="Search" />
                  <Button className="btn btn-outline-success" type="submit">Cari</Button>
                </Form>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover">
            <thead>
              <tr className="text-center">
                <th>No.</th>
                <th>Nama Konten</th>
                <th>Konten</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {!!Display && Display ? (
                Display.map((item, i) => (
                  <tr key={item.content_id}>
                    <td className="text-center">{i + 1}</td>
                    <td>{item.nama_content}</td>
                    <td className="text-center">
                      <video src={direktori + item.file} controls muted loop autoPlay={"autoplay"} preload="auto" type="video/mp4" className="data-content" />
                    </td>
                    <td className="text-center">
                      <Button onClick={() => handleShowEdit(item)} className="edit" data-toggle="modal"><i className="fas fa-edit" data-toggle="tooltip"></i> Edit</Button>
                      <Button onClick={() => Hapus(item.content_id)} className="delete" data-toggle="modal"><i className="fas fa-trash" data-toggle="tooltip"></i> Hapus</Button>
                    </td>
                  </tr>
                ))) : (<div>Tidak ada data</div>)}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                <button class="page-link" aria-label="Previous" onClick={() => Pagination(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {pageNumbers}
              <li className={currentPage === Total ? 'page-item disabled' : 'page-item'}>
                <button class="page-link" aria-label="Next" onClick={() => Pagination(currentPage + 1)}>
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        {/* Modal tambah display */}
        <Modal size="lg" show={showAdd} onHide={handleCloseAdd} animation={true} className="modal-crud">
          <Form onSubmit={Tambah} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="far fa-building"></i></span>
                <span className="crud-judul"> Tambah</span><span className="crud-header"> Konten</span>
              </Modal.Title>
              <Button onClick={handleCloseAdd} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Nama Konten</Form.Label>
                <Form.Control type="text" placeholder='Content Name' value={nama_content} onChange={(e) => setNamaContent(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Konten</Form.Label>
                <Form.Control type="file" accept='video/mp4,video/x-m4v,video/*' onChange={handleChange} required />
                {file ? (
                  <video src={file} controls muted loop autoPlay={"autoplay"} preload="auto" type="video/mp4" className="file-preview" />
                ) : (
                  <span className="fa-stack fa-2x mt-3 mb-2">
                    <i className="fas fa-circle fa-stack-2x" />
                    <i className="fas fa-file-video fa-stack-1x fa-inverse" />
                  </span>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseAdd} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* modal edit display */}
        <Modal size="lg" show={showEdit} onHide={handleCloseEdit} animation={true} className="modal-crud">
          <Form onSubmit={Edit} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="far fa-building"></i></span>
                <span className="crud-judul"> Edit</span><span className="crud-header"> Konten</span>
              </Modal.Title>
              <Button onClick={handleCloseEdit} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Nama Konten</Form.Label>
                <Form.Control type="text" placeholder='Content Name' value={nama_content} onChange={(e) => setNamaContent(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Konten</Form.Label>
                <Form.Control type="file" accept='video/mp4,video/x-m4v,video/*' onChange={handleChange} required />
                {file ? (
                  <video src={file} controls muted loop autoPlay={"autoplay"} preload="auto" type="video/mp4" className="file-preview" />
                ) : (
                  <span className="fa-stack fa-2x mt-3 mb-2">
                    <i className="fas fa-circle fa-stack-2x" />
                    <i className="fas fa-store fa-stack-1x fa-inverse" />
                  </span>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseEdit} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default ListDisplay;