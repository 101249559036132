import React from 'react';
import Signin from '../component/auth/login';

function Login() {
  return (
      <div className='bg-auth justify-content-center'>
        <video className="bg-video" src={require(`../assets/mp4/bg.mp4`)} muted loop autoPlay={"autoplay"} preload="auto" type="video/mp4" />
        <Signin />
      </div>
    );
  }
  
  export default Login;