import React from 'react';

function Footer() {
    return(
      <div style={{padding: '20px'}}>
        <div className="justify-content-md-center">
          <div className="text-center">Copyright © {new Date().getFullYear()} . <a className="text-decoration-none text-black" href="http://javis.co.id/">PT. Javis Teknologi Albarokah</a></div>
        </div>
      </div>
    );
}

export default Footer;