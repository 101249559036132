import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Javis from '../../assets/image/javis.png';
import Dishub from '../../assets/image/dishub.png';
import { useNavigate } from 'react-router-dom';

function LupaPassword() {
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  // kelola lupa password
  const Forgot = async (e) => {
    try {
      await axios.post('/GantiPassword', {
        email: email
      });
      navigate("/Login");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  }
  return (
    <div className='posisi-tengah'>
      <div className='konten-lupa-password card'>
        <h3 className="header-title text-center">Lupa Password</h3>
        {msg ? (
          <h4>{msg}</h4>
        ) : (null)}
        <Form onSubmit={Forgot}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control id="inputEmail" type="email" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>
          <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
            <a className="small text-black" href="login">kembali login</a>
            <Button type="submit" className="btn btn-primary">Kirim</Button>
          </div>
        </Form>
      </div>
      <div className='d-flex justify-content-center mt-2'>
        <img src={Javis} style={{ width: "40px", height: '40px' }} alt="Javis" />
        <img src={Dishub} style={{ width: "40px", height: '40px' }} alt="Dishub" />
      </div>
      <div className='d-flex justify-content-center'>
        <p className='text-center text-white'>Dikembangkan oleh: <br /> PT. Javis Teknologi Albarokah</p>
      </div>
    </div>
  );
}

export default LupaPassword;