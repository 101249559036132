import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HalteMap from './halteMap';
import axios from 'axios';
import swal from 'sweetalert';

function ListHalte() {
  const [Halte, setHalte] = useState([])
  const [Display, setDisplay] = useState([])
  const [halte_id, setHalteId] = useState('')
  const [nama, setNama] = useState('')
  const [alamat, setAlamat] = useState('')
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')
  const [content_id, setContent] = useState('')
  const [kordinat, setKordinat] = useState([])
  const [lokasi, setLokasi] = useState([])
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');
  useEffect(() => {
    getHalte();
    getDisplay();
    getLokasi();
  }, []);

  // dapatkan lokasi saat ini
  const getLokasi = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setLokasi(pos);
      }, function () {
        // browser tidak support geolocation
        let pos = { lat: -7.747872, lng: 110.4218147 };
        setLokasi(pos);
      });
    }
  }

  // data perubahan halte
  const kembalian = (value) => {
    setLat(value.lat)
    setLng(value.lng)
    setAlamat(value.alamat)
  }

  // dapatkan data halte
  const getHalte = async () => {
    const response = await axios.get(`/halte?page=${currentPage}&limit=${limit}`);
    setHalte(response.data[0]);
    setTotal(Math.ceil(response.data[1].jumlah / limit));
  }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/halte/cari/?page=${currentPage}&limit=${limit}&key=${key}`);
      setHalte(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    } else {
      const response = await axios.get(`/halte?page=${currentPage}&limit=${limit}`);
      setHalte(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    }
  }

  // dapatkan data display halte
  const getDisplay = async () => {
    const response = await axios.get('/content');
    setDisplay(response.data);
  }

  // tambah data halte
  const Tambah = async (e) => {
    e.preventDefault();
    await axios.post('/halte', {
      nama: nama,
      alamat: alamat,
      lat: lat,
      lng: lng,
      content_id: content_id
    });
    await axios.get(process.env.REACT_APP_REFRESH);
    getHalte();
  }

  // edit data halte
  const Edit = async (e) => {
    e.preventDefault();
    await axios.put(`/halte/${halte_id}`, {
      nama: nama,
      alamat: alamat,
      lat: lat,
      lng: lng,
      content_id: content_id
    });
    await axios.get(process.env.REACT_APP_REFRESH);
    getHalte();
  }

  // hapus data halte
  const Hapus = async (halte_id) => {
    swal({
      title: "Apakah Anda yakin akan menghapus data ini ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.delete('/halte/' + halte_id)
          swal("Data Halte Sukses dihapus", {
            icon: "success",
          })
          getHalte();
        } else {
          swal("Data tidak terhapus");
        }
      });
    await axios.get(process.env.REACT_APP_REFRESH);
  }

  // handle tambah data halte
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);

  // handle edit data halte
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
    setHalteId('');
    setNama('');
    setAlamat('');
    setLat('');
    setLng('');
    setContent('');
    setKordinat([]);
  }
  const handleShowEdit = (data) => {
    setShowEdit(true);
    setHalteId(data.halte_id);
    setNama(data.nama);
    setAlamat(data.alamat);
    setLat(data.lat);
    setLng(data.lng);
    setContent(data.content);
    setKordinat(data);
  }

  const Pagination = async (page) => {
    setCurrentPage(page);
    getHalte();
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <li className={currentPage === i ? 'page-item active' : 'page-item'}><button class="page-link" onClick={() => Pagination(i)}>{i}</button></li>
    );
  }

  return (
    <div className="container-xl">
      <h1 className="teks-hitam">Daftar Halte</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Beranda</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Daftar Halte
          </li>
        </ol>
      </nav>
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="d-flex justify-content-between">
              <div>
                <Button onClick={handleShowAdd} className="btn btn-success"><i className="fas fa-plus" /> <span>Tambah Halte&ensp;</span></Button>
              </div>
              <div>
                <Form onSubmit={filter} className="d-flex justify-content-end">
                  <Form.Control style={{ width: '15rem' }} value={key} className="me-2" onChange={(e) => setKey(e.target.value)} type="text" placeholder="Pencarian" aria-label="Search" />
                  <Button className="btn btn-outline-success" type="submit">Cari</Button>
                </Form>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama Halte</th>
                <th>Tampilan Halte</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {!!Halte && Halte ? (
                Halte.map((item, i) => (
                  <tr key={item.halte_id}>
                    <td>{i + 1}</td>
                    <td>{item.nama}</td>
                    <td>
                      <Link to={`/display-halte/${item.halte_id}`} className="detail"><i className="far fa-building"></i> Lihat Tampilan</Link>
                    </td>
                    <td>
                      <Link to={`/detail-halte/${item.halte_id}`} className="detail" data-toggle="modal"><i className="fas fa-info-circle"></i> Detail</Link>&ensp;
                      <Button onClick={() => handleShowEdit(item)} className="edit" data-toggle="modal"><i className="fas fa-edit" data-toggle="tooltip"></i> Edit</Button>
                      <Button onClick={() => Hapus(item.halte_id)} className="delete" data-toggle="modal"><i className="fas fa-trash" data-toggle="tooltip"></i> Hapus</Button>
                    </td>
                  </tr>
                ))) : (<div>Tidak ada data</div>)}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                <button class="page-link" aria-label="Previous" onClick={() => Pagination(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {pageNumbers}
              <li className={currentPage === Total ? 'page-item disabled' : 'page-item'}>
                <button class="page-link" aria-label="Next" onClick={() => Pagination(currentPage + 1)}>
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        {/* modal tambah halte */}
        <Modal size="lg" show={showAdd} onHide={handleCloseAdd} animation={true} className="modal-crud">
          <Form onSubmit={Tambah} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-store-alt" /></span>
                <span className="crud-judul"> Tambah</span><span className="crud-header"> Halte</span>
              </Modal.Title>
              <Button onClick={handleCloseAdd} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Nama Halte</Form.Label>
                <Form.Control type="text" value={nama} onChange={(e) => setNama(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Alamat</Form.Label>
                <Form.Control type="text" value={alamat} onChange={(e) => setAlamat(e.target.value)} required />
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="crud-label">Latitude</Form.Label>
                    <Form.Control type="text" value={lat} onChange={(e) => setLat(e.target.value)} required />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="crud-label">Longtitude</Form.Label>
                    <Form.Control type="text" value={lng} onChange={(e) => setLng(e.target.value)} required />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label className="crud-label">Konten Display</Form.Label>
                <Form.Select aria-label="Default select example">
                  {Display.map((data) => {
                    return (
                      <option selected key={data.content_id} value={data.content_id} onChange={(e) => setContent(e.target.value)}>{data.nama_content}</option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <h5 className='text-center'>Silahkan klik area pada map untuk menentukan posisi halte</h5>
                <div className="modal-map">
                  <HalteMap lokasi={lokasi} onChangeAlamat={kembalian} />
                </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseAdd} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* modal edit halte */}
        <Modal size="lg" show={showEdit} onHide={handleCloseEdit} animation={true} className="modal-crud">
          <Form onSubmit={Edit} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-store-alt" /></span>
                <span className="crud-judul"> Edit</span><span className="crud-header"> Halte</span>
              </Modal.Title>
              <Button onClick={handleCloseEdit} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Nama Halte</Form.Label>
                <Form.Control type="text" value={nama} onChange={(e) => setNama(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Alamat</Form.Label>
                <Form.Control type="text" value={alamat} onChange={(e) => setAlamat(e.target.value)} required />
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="crud-label">Latitude</Form.Label>
                    <Form.Control type="text" value={lat} onChange={(e) => setLat(e.target.value)} required />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="crud-label">Longtitude</Form.Label>
                    <Form.Control type="text" value={lng} onChange={(e) => setLng(e.target.value)} required />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label className="crud-label">Konten Display</Form.Label>
                <Form.Select aria-label="Default select example">
                  {Display.map((data) => {
                    return (
                      <option selected key={data.content_id} value={data.content_id} onChange={(e) => setContent(e.target.value)}>{data.nama_content}</option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <h5 className='text-center'>Silahkan klik area pada map untuk menentukan posisi halte</h5>
                <div className="modal-map">
                  <HalteMap places={kordinat} lokasi={kordinat} onChangeAlamat={kembalian} />
                </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseEdit} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default ListHalte;