import React from 'react';
import Header from '../component/template/Header';
import List from '../component/trayek/listTrayek';
import Footer from '../component/template/Footer';

function ListTrayek() {
  return (
    <div>
      <Header />
      <div className='content'>
        <div className="tinggi-content">
          <List />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ListTrayek;