import React, { useState } from 'react';
import './login.css';
import Javis from '../../assets/image/javis.png';
import Dishub from '../../assets/image/dishub.png';
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { Input, InputAdornment, IconButton } from "@material-ui/core";
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  // kelola login
  const Auth = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/login', {
        username: username,
        password: password
      }).then(response => {
        localStorage.setItem('x-access-token', response.data.token);
        localStorage.setItem('x-access-token-expiration', Date.now() + 2 * 60 * 60 * 1000);
        localStorage.setItem('id_user', response.data.user.user_id);
      });
      navigate('/dashboard');
    } catch (error) {
      if (error.response) {
        setMsg(<h4 className='pesan-error'>{error.response.data.msg}</h4>);
      }
    }
  }

  return (
    <div className='posisi-tengah'>
      <div className='konten-login card'>
        <h3 className="fw-bolder text-center">Masuk</h3>
        {/* <p className="fw-lighter fs-6">Belum punya akun? <a href='/Registrasi' role="button" className="text-primary">Daftar disini</a></p> */}
        {msg ? (
          <h4>{msg}</h4>
        ) : ('')}
        <Form onSubmit={Auth}>
          <div className="mb-3">
            <Form.Label htmlFor="username">Username</Form.Label>
            <Input type="username" className="form-control text-indent shadow-sm fw-lighter fs-7" placeholder="username" value={username} onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="username" className="form-label">Password</Form.Label>
            <div className="d-flex position-relative">
              <Input className="form-control text-indent shadow-sm fw-lighter fs-7" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}
                type={values.showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                } />
            </div>
          </div>
          <div className='text-end'>
            <a role="button" className="text-primary" href="/LupaPassword">Lupa Password?</a>
          </div>
          <div className="col text-center">
            <Button type="submit" className="btn btn-primary mt-2 w-100">Masuk</Button>
          </div>
        </Form>
      </div>
      <div className='d-flex justify-content-center mt-4'>
        <img src={Javis} style={{ width: "40px", height: '40px' }} alt="Javis" />
        <img src={Dishub} style={{ width: "40px", height: '40px' }} alt="Dishub" />
      </div>
      <div className='d-flex justify-content-center'>
        <p className='text-center text-white'>Dikembangkan oleh: <br /> PT. Javis Teknologi Albarokah</p>
      </div>
    </div>
  );
}

export default Login;