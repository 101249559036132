import './tracking.css';
import gambar from '../../assets/image/bus.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TrackingMap from './trackingMap';
import { Form, FormControl, Button } from 'react-bootstrap';
import { Menu, MenuItem, ProSidebar, SidebarHeader } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

function Tracking() {
  const [Bus, setBus] = useState([]);
  const [Halte, setHalte] = useState([]);
  const [Trayek, setTrayek] = useState([]);
  const [filterBus, setFilterBus] = useState([]);
  const [nama, setNama] = useState('');
  const [lokasi, setLokasi] = useState([]);
  useEffect(() => {
    getBus();
    getHalte();
    getTrayek();
    getLokasi();
  }, []);

  // dapatkan lokasi saat ini
  const getLokasi = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setLokasi(pos);
      });
    } else {
      // Browser doesn't support Geolocation
      let pos = { lat: -7.747872, lng: 110.4218147 };
      setLokasi(pos);
    }
  }

  // ambil data bus dari backend
  const getBus = async () => {
    const response = await axios.get('/bus');
    setBus(response.data);
    setFilterBus(response.data);
  }

  // ambil data halte dari backend
  const getHalte = async () => {
    const response = await axios.get('/trayek_halte/halte');
    setHalte(response.data);
  }

  // ambil data trayek dari backend
  const getTrayek = async () => {
    const response = await axios.get('/trayek_halte/trayek');
    setTrayek(response.data);
  }

  // filter data dalam pencarian
  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const perNama = Bus.filter((bus) => {
        return bus.nama.toLowerCase().startsWith(keyword.toLowerCase());
      });
      const perPlat = Bus.filter((bus) => {
        return bus.nopol.toLowerCase().startsWith(keyword.toLowerCase());
      });
      if (perNama.length > 0) {
        setFilterBus(perNama);
      } else {
        setFilterBus(perPlat)
      }
    } else {
      setFilterBus(Bus);
    }
    setNama(keyword);
  }
  let stats;
  const [collapsed, setCollapsed] = useState(false);

  const styles = {
    sideBarHeight: {
      height: "100vh"
    },
    menuIcon: {
      float: "right",
      margin: "10px"
    },
    contentDiv: {
      display: "flex"
    },
    contentMargin: {
      width: "100%",
      position: "relative"
    }
  };
  const onClickMenuIcon = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <div style={styles.contentDiv}>
        <ProSidebar style={styles.sideBarHeight} collapsed={collapsed}>
          <SidebarHeader>
            <div style={styles.menuIcon} onClick={onClickMenuIcon}>
              <i className="fas fa-bars"></i>
            </div>
          </SidebarHeader>
          <SidebarHeader>
            <Form className="d-flex">
              <FormControl type="search" value={nama} onChange={filter} placeholder="Search" className="me-2" aria-label="Search" />
              <Button variant="outline-success">Cari</Button>
            </Form>
          </SidebarHeader>
          <Menu>
            {filterBus && filterBus.length > 0 ? (
              filterBus.map((data) => {
                var jam = 1000 * 60 * 60;
                var waktu = new Date() - jam;
                if (data.last_update <= waktu) {
                  stats = <strong className="mb-1 online"><i className="fas fa-circle"></i> Online</strong>;
                } else {
                  stats = <strong className="mb-1 offline"><i className="fas fa-circle"></i> Offline</strong>;
                }
                return (
                  <MenuItem key={data.bus_id} className="daftarBus">
                    <hr />
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <img src={gambar} className='bus' alt="bus" />
                      <h4 className="mb-1">{data.nama}</h4>
                      {stats}
                    </div>
                    <div className="mb-1 small fs-5">{data.nopol}</div>
                  </MenuItem>
                );
              })) : (
              <h3>Tidak ada Bus ditemukan</h3>
            )}
          </Menu>
        </ProSidebar>
        <div style={styles.contentMargin}>
          <TrackingMap places={Halte} lokasi={lokasi} trayek={Trayek} bus={Bus} ip={process.env.REACT_APP_WEBSOCKET_URL} />
        </div>
        <div id="sidebar"></div>
      </div>

      {/* Basic features section*/}
      <section className="bg-gray">
        <div className="container">
          <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
            <div className="col-12">
              <h2 className="display-5 lh-1 mb-4 text-center">Daftar Rute dan Halte</h2>
              {!!Trayek && Trayek ? (
                Trayek.map((data) => {
                  var Datahalte = data.nama_halte;
                  var halte = Datahalte.split(',');
                  return (
                    <div>
                      <h5>{data.nama}</h5>
                      <ul className="ratings">
                        {halte.map(i => {
                          return (
                            <li>{i}</li>
                          )
                        })}
                      </ul>
                    </div>
                  )
                })) : (
                <h5>Loading...</h5>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Tracking;