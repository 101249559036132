import React from 'react';
import Header from '../component/template/Header';
import List from '../component/userGroup/listUserGroup';
import Footer from '../component/template/Footer';

function ListUserGroup() {
  return (
    <div>
      <Header />
      <div className="content">
        <div className="tinggi-content">
        <List />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ListUserGroup;