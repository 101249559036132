import React from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import Kemhub from '../../assets/image/kemhub.png';

function Footer() {
    return(
    <div className='bg-dark' id="dashboardNav">
      <Container>
        <Row className="pt-4 pb-2">
          <Col lg={6} md={4} className="mt-2 pe-4 align-left">
            <Navbar.Brand className='align-self-center'>
              <img src={Kemhub} style={{height:"80px"}} alt="Dishub" />
            </Navbar.Brand>
            <p className="text-light my-lg-4 my-2"> Tujuan PTIS adalah pengembangan angkutan umum di kawasan perkotaan berbasis jalan yang menggunakan teknologi telematika yang andal dan berbasis non tunai untuk meningkatkan keselamatan dan keamanan serta kenyamanan mobilisasi Anda.</p>
          </Col>
            <Col lg={3} md={4} className="mt-2">
              <h3 className="h4 pb-lg-3 text-light light-300">Halaman</h3>
                <ul className="list-unstyled text-light light-300">
                  <li className="pb-2">
                    <a className='text-decoration-none footer-link py-1' href="/"><i className="fas fa-home"/> Beranda</a>
                  </li>
                  <li className="pb-2">
                    <a className='text-decoration-none footer-link py-1' href="/Rute"><i className="fas fa-route" /> Rute</a>
                  </li>
                  <li className="pb-2">
                    <a className="text-decoration-none footer-link py-1" href="/Kontak"><i className="fas fa-address-book" /> Kontak</a>
                  </li>
                 </ul>
            </Col>
            <Col lg={3} md={4} className="mt-2">
                <h2 className="h4 pb-lg-3 text-light light-300">Kontak</h2>
                <ul className="list-unstyled text-light light-300">
                    <li className="pb-2">
                        <i className='bx-fw bx bx-phone bx-xs'></i><a className="text-decoration-none footer-link py-1" href="tel:010-020-0340">(0274) 4477267</a>
                    </li>
                    <li className="pb-2">
                        <i className='bx-fw bx bx-mail-send bx-xs'></i><a className="text-decoration-none footer-link py-1" href="mailto:javis@javis.co.id">javis@javis.co.id</a>
                    </li>
                </ul>
                <ul className="list-inline footer-icons light-300">
                  <li className="list-inline-item m-0">
                    <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/javis.teknologi.1">
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li className="list-inline-item m-0 px-2">
                    <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/jogjavision/">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item m-0 px-2">
                    <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://www.whatsapp.com/">
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </li>
                  <li className="list-inline-item m-0 px-2">
                    <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://www.twitter.com/">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item m-0 px-2">
                    <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://www.javis.co.id/">
                      <i className="fas fa-globe"></i>
                    </a>
                  </li>
                </ul>
            </Col>
        </Row>
        <Row className="justify-content-md-center pb-2">
          <Col className="text-center text-light">Copyright © {new Date().getFullYear()} . <a className="text-decoration-none footer-link mb-3" href="http://javis.co.id/">PT. Javis Teknologi Albarokah</a></Col>
        </Row>
      </Container>
    </div>
    );
}

export default Footer;