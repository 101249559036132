import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import IconHalte from '../../assets/image/halte2.png';

const customizeMap = [
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
];
const ukuran = {
    position: "relative",
    width: "250px",
    height: "180px"
};

class Maps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            halte: this.props.halte,
        }
    }

    render() {
        return (
            <Map
                google={this.props.google}
                styles={customizeMap}
                style={ukuran}
                zoom={15}
                disableDefaultUI={true}
                initialCenter={{ lat: this.props.halte.lat, lng: this.props.halte.lng }}>

                {/* Marker Halte */}
                <Marker
                    onClick={this.onMarkerClick}
                    key={this.props.halte.halte_id}
                    place_={this.props.halte}
                    position={{ lat: this.props.halte.lat, lng: this.props.halte.lng }}
                    icon={{
                        url: IconHalte,
                        scaledSize: new this.props.google.maps.Size(70, 55)
                    }}
                />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCnTyLMTagAytZaQfW7pcZTFAd1p3NE7Bc'
})(Maps);