import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Jam, Tanggal } from '../template/waktu';
import '../../assets/css-js/template.css';
import '../../assets/css-js/style.css';
import Contoh from '../template/contoh';
import Footer from '../template/Footer';

function Rute() {
  const [Trayek, setTrayek] = useState([]);
  const [Lokasi, setLokasi] = useState([]);
  const [Halte, setHalte] = useState([]);
  const [Bus, setBus] = useState([]);
  const [socketData, setSocketData] = useState([]);
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  var arrayBus = [];
  useEffect(() => {
    getLokasi();
    getTrayek();
    getHalte();
    getBus();
    connect_socket();
  }, []);

  useEffect(() => {
    if (socketData) {
      setBus(socketData);
    }
  }, [socketData]);

  const getLokasi = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setLokasi(pos);
      });
    } else {
      // Browser doesn't support Geolocation
      let pos = { lat: -7.747872, lng: 110.4218147 };
      setLokasi(pos);
    }
  }

  const getTrayek = async () => {
    const response = await axios.get(`/trayek_halte/trayek`);
    setTrayek(response.data);
  }

  const getHalte = async () => {
    const response = await axios.get(`/trayek_halte/halte`);
    setHalte(response.data);
  }

  const getBus = async () => {
    const response = await axios.get(`/bus?page=${currentPage}&limit=${limit}`);
    setBus(response.data[0]);
    arrayBus = response.data[0];
    setTotal(Math.ceil(response.data[1].jumlah / limit));
  }

  // koneksi websocket
  const connect_socket = () => {
    let sc = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
    sc.onmessage = function (ev) {
      console.log('ws message')
      try {
        var d = JSON.parse(ev.data);
        let cariBus = arrayBus.findIndex(dataBus => dataBus.bus_id === d.bus_id);

        // jika data bus tidak kosong
        if (cariBus !== -1) {
          arrayBus[cariBus] = d;
          setSocketData(arrayBus);
        }
      } catch (e) {
        console.log("coba lagi");
      }
    }
    sc.onopen = function (ev) {
      console.log('ws openned');
    }
    sc.onerror = function (ev) {
      console.log('ws error');
      console.log(ev);
      sc.close();
    }
    sc.onclose = function (ev) {
      console.log('ws closed');
      console.log(ev);
      setTimeout(function () {
        connect_socket();
      }, 1000);
    }
  }

  const Pagination = async (page) => {
    setCurrentPage(page);
    getBus();
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <li className={currentPage === i ? 'page-item active' : 'page-item'}><button className="page-link" onClick={() => Pagination(i)}>{i}</button></li>
    );
  }

  return (
    <div id="page-top">
      <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
        <div className="container px-5">
          <a className="navbar-brand fw-bold" href="/">
            <img src={require('../../assets/image/kemhub.png')} style={{ height: "50px" }} alt="logo-kemhub" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fas fa-bars" /> Menu
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
              <li className="nav-item"><a className="nav-link me-lg-3" href="/"><i className="fas fa-home" /> Beranda</a></li>
              <li className="nav-item"><a className="nav-link me-lg-3 link-aktif" href="/Rute"><i className="fas fa-route" /> Rute</a></li>
              <li className="nav-item"><a className="nav-link me-lg-3" href="/Kontak"><i className="fas fa-address-book" /> Kontak</a></li>
            </ul>
            <div className='dropdown-link'>
              <button className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0">
                <span className="d-flex align-items-center small">Download</span>
              </button>
              <div className="dropdown-os">
                <a href="https://play.google.com/store/apps/" target="_blank" rel="noopener noreferrer"><i className="fab fa-google-play" />&ensp;Android</a>
                <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-app-store-ios" />&ensp;IOS</a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div id="rute">
        <section className="bg-light masthead">
          <div className="container px-5">
            <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
              <div className="col-12 col-lg-7">
                <h2 className="display-5 lh-1 mb-4">Public Transport Information System (PTIS)</h2>
                <p className="lead fw-normal text-muted mb-5 mb-lg-0 rata-kanan-kiri">PTIS berupa kendaraan Bus Sedang yang berkapasitas 40 penumpang dengan 20 tempat duduk dan Bus Besar yang berkapasitas 60 penumpang dengan 30 tempat duduk. Serta masing-masing terdapat 1 area untuk prioritas. PTIS dilengkapi dengan CCTV dan sensor alarm pengemudi adalah salah satu upaya untuk memberikan rasa aman bagi penumpang.</p>
              </div>
              <div className="col-sm-6 col-md-5 justify-content-center">
                <div className="px-5 px-sm-0"><img src={require('../../assets/image/bus-tracking.png')} className="img-fluid" alt="Jalur" /></div>
              </div>
            </div>
          </div>
        </section>
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <div className='text-bold h4'>Rute Bus PTIS</div>
            <div><Tanggal /> <Jam /></div>
          </div>
          <div className='card-body rute-map'>
            <Contoh lokasi={Lokasi} halte={Halte} bus={Bus} trayek={Trayek} ip={process.env.REACT_APP_WEBSOCKET_URL} />
          </div>
        </div>
        {/* Basic features section*/}
        <section className="bg-light">
          <div className="container px-5">
            <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
              <div className="col-12">
                <h2 className="display-5 lh-1 mb-4">Jadwal Kedatangan Bus</h2>
                <div className="table-responsive">
                  <div className="table-wrapper">
                    <table id='jadwal' className='mb-3'>
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Trayek Bus</th>
                          <th>Halte Keberangkatan</th>
                          <th>Halte Tujuan</th>
                          <th>Estimasi Sampai</th>
                          {/* <th className='text-center'>Lokasi Bus</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {!!Bus && Bus ? (
                          Bus.map((item, i) => (
                            <tr key={item.bus_id}>
                              <td>{i + 1}</td>
                              <td>{item.nama}</td>
                              <td>{item.dari}</td>
                              <td>{item.tujuan}</td>
                              <td>{item.waktu}</td>
                              {/* <td className='d-flex justify-content-center'><button type="button" className="btn btn-primary text-center">Lihat Lokasi</button></td> */}
                            </tr>
                          ))) : (<div>Tidak ada data</div>)}
                      </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                          <button className="page-link" aria-label="Previous" onClick={() => Pagination(currentPage - 1)}>
                            <span aria-hidden="true">&laquo;</span>
                          </button>
                        </li>
                        {pageNumbers}
                        <li className={currentPage === Total ? 'page-item disabled' : 'page-item'}>
                          <button className="page-link" aria-label="Next" onClick={() => Pagination(currentPage + 1)}>
                            <span aria-hidden="true">&raquo;</span>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Rute;