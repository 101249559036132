import React from 'react';
import { render } from 'react-dom';
import App from './App';
import axios from 'axios';

var token = localStorage.getItem["x-access-token"]
axios.defaults.baseURL = 'http://backend-ptis.javis.co.id/';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = token;

render(
  <App />,
  document.getElementById('root')
);