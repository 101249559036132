import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import Geocode from "react-geocode";
import Icon from '../../assets/image/halte2.png';

const customizeMap = [
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];
const ukuran = {
  position: "relative",
  width: "100%",
  height: "100%"
};

class HalteMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Alamat: [],
      Koordinat: [],
      posisi: this.props.lokasi
    }
  }

  // dapatkan titik koordinat
  getPosisi = (ref, map, ev) => {
    const getLat = ev.latLng.lat();
    const getLng = ev.latLng.lng();
    const posisi = { lat: getLat, lng: getLng }
    this.setState({
      posisi: posisi
    });
    this.getAlamat(posisi);
  };

  // dapatkan alamat
  getAlamat = (posisi) => {
    Geocode.setKey("AIzaSyCnTyLMTagAytZaQfW7pcZTFAd1p3NE7Bc");
    // dapatkan alamat dari koordinat
    Geocode.fromLatLng(posisi.lat, posisi.lng).then(
      (response) => {
        const Alamat = response.results[0].formatted_address;
        this.setState({ Alamat });
        this.props.onChangeAlamat({
          lat: posisi.lat,
          lng: posisi.lng,
          alamat: Alamat
        })
      },
      (error) => {
        console.error(error);
      }
    );
  }

  // dapatkan koordinat dari alamat
  getKoordinat = (props) => {
    Geocode.setApiKey("AIzaSyCnTyLMTagAytZaQfW7pcZTFAd1p3NE7Bc");
    // Dapat latitude & longitude dari alamat.
    Geocode.fromAddress(props).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
      },
      (error) => {
        console.error(error);
      }
    );
  }
  render() {
    return (
      <Map
        google={this.props.google}
        styles={customizeMap}
        style={ukuran}
        zoom={15}
        onClick={this.getPosisi}
        initialCenter={!!this.props.places && this.props.places ? (
          { lat: this.props.places.lat, lng: this.props.places.lng }
        ) : (this.props.lokasi)}>
        <Marker
          position={this.state.posisi}
          icon={{
            url: Icon,
            scaledSize: new this.props.google.maps.Size(70, 55)
          }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCnTyLMTagAytZaQfW7pcZTFAd1p3NE7Bc'
})(HalteMap);