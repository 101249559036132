import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Geocode from "react-geocode";
import HalteMap from '../halte/halteMap';
import { Jam, Tanggal, Gambar, Hari } from '../template/waktu';
import { Container, Row, Col, Card, Modal, Button, Form } from 'react-bootstrap';

function DetailHalte() {
    const { halte_id } = useParams();
    const [Display, setDisplay] = useState([]);
    const [Halte, setHalte] = useState([]);
    const [nama, setNama] = useState('');
    const [alamat, setAlamat] = useState([]);
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [content_id, setContent] = useState('');
    const direktori = process.env.REACT_APP_FILE_URL;
    useEffect(() => {
        getHalteById();
        getDisplay();
    }, []);

    // dapatkan data halte berdasarkan id
    const getHalteById = async () => {
        const response = await axios.get(`/trayek_halte/halte/${halte_id}`);
        setHalte(response.data[0]);
        Geocode.setApiKey("AIzaSyCnTyLMTagAytZaQfW7pcZTFAd1p3NE7Bc");
        // dapatkan alamat dari koordinat
        Geocode.fromLatLng(response.data[0].lat, response.data[0].lng).then(
            (response) => {
                const alamatHalte = response.results[0].formatted_address;
                setAlamat(alamatHalte);
            },
            (error) => {
                console.error(error);
            }
        );
    }

    // dapatkan data display halte
    const getDisplay = async () => {
        const response = await axios.get('/content');
        setDisplay(response.data);
    }

    // data perubahan halte
    const kembalian = (value) => {
        setLat(value.lat)
        setLng(value.lng)
        setAlamat(value.alamat)
    }

    // edit data halte
    const Edit = async (e) => {
        e.preventDefault();
        await axios.put(`/halte/${halte_id}`, {
            nama: nama,
            alamat: alamat,
            lat: lat,
            lng: lng,
            content_id: content_id
        });
        await axios.get(process.env.REACT_APP_REFRESH)
    }

    // edit data halte
    const [editHalte, setEditHalte] = useState(false);
    const handleEditHalte = () => setEditHalte(true);
    const handleTutupEditHalte = () => setEditHalte(false);

    return (
        <Container className='mt-3'>
            <Row>
                <Col sm={12}>
                    <Card>
                        <Card.Header>
                            <div className='d-flex justify-content-between'>
                                <h3><b>{Halte.nama}</b></h3>
                                <div>
                                    <Button onClick={handleEditHalte} className="btn btn-warning"><i className="fas fa-edit"></i> Ubah Halte</Button>&ensp;
                                    <Link to={`/PrintDetail/${halte_id}`} target="_blank" className="btn btn-success"><i className="fas fa-print"></i> Cetak</Link>&ensp;
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={6}>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <Gambar />
                                        </div>
                                        <div className='col-10'>
                                            <h3 className="display-text"><Hari />,</h3>
                                            <h3 className="display-text"><Tanggal /></h3>
                                        </div>
                                        <h1 className="display-text"><Jam /></h1>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className='d-flex justify-content-end'>
                                        <img src={require('../../assets/image/dishub.png')} alt='dishub' style={{ height: '100px' }} />
                                        <img src={require('../../assets/image/ptis.png')} alt='ptis' style={{ height: '100px' }} />
                                    </div>
                                </Col>
                            </Row>
                            <table className="table table-sm">
                                <tbody>
                                    <tr>
                                        <td>Nama Halte</td>
                                        <td>:</td>
                                        <td>{Halte.nama}</td>
                                    </tr>
                                    <tr>
                                        <td>Alamat Halte</td>
                                        <td>:</td>
                                        <td>{alamat}</td>
                                    </tr>
                                    <tr>
                                        <td>Rute</td>
                                        <td>:</td>
                                        <td>{Halte.nama_trayek}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h5 className="card-title text-center"><b>Konten Tampilan Halte</b></h5>
                            <video src={direktori + Halte.file} controls muted loop autoPlay={"autoplay"} preload="auto" className="display" type="video/mp4" />
                        </Card.Body>
                    </Card>
                </Col>

                {/* modal edit halte */}
                <Modal size="lg" show={editHalte} onHide={handleTutupEditHalte} animation={true} className="modal-crud">
                    <Form onSubmit={Edit} className="form-box">
                        <Modal.Header className="header-modal">
                            <Modal.Title>
                                <span className="crud-icon"><i className="fas fa-store-alt" /></span>
                                <span className="crud-judul"> Edit</span><span className="crud-header"> Halte</span>
                            </Modal.Title>
                            <Button onClick={handleTutupEditHalte} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
                        </Modal.Header>
                        <Modal.Body className="form-crud">
                            <Form.Group>
                                <Form.Label className="crud-label">Nama Halte</Form.Label>
                                <Form.Control type="text" value={Halte.nama} onChange={(e) => setNama(e.target.value)} required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="crud-label">Alamat</Form.Label>
                                <Form.Control type="text" value={alamat} onChange={(e) => setAlamat(e.target.value)} required />
                            </Form.Group>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label className="crud-label">Latitude</Form.Label>
                                        <Form.Control type="text" value={Halte.lat} onChange={(e) => setLat(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label className="crud-label">Longtitude</Form.Label>
                                        <Form.Control type="text" value={Halte.lng} onChange={(e) => setLng(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group>
                                <Form.Label className="crud-label">Konten Display</Form.Label>
                                <Form.Select aria-label="Default select example">
                                    {Display.map((data) => {
                                        return (
                                            <option selected key={data.content_id} value={data.content_id} onChange={(e) => setContent(e.target.value)}>{data.nama_content}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <h5 className='text-center'>Silahkan klik area pada map untuk menentukan posisi halte</h5>
                                <div className="modal-map">
                                    <HalteMap places={Halte} lokasi={Halte} onChangeAlamat={kembalian} />
                                </div>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <Button onClick={handleTutupEditHalte} className="btn btn-danger">Batal</Button>
                            <Button variant="btn btn-primary" type="submit">Simpan</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Row>
        </Container>
    )
}

export default DetailHalte;