import React from 'react';
import Display from '../component/displayHalte/display';

function DisplayHalte() {
  return (
      <div>
          <Display />
      </div>
  );
}

export default DisplayHalte;