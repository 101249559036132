import React, { useEffect, useState } from 'react';
import './bus.css';
import './modal.css';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import swal from 'sweetalert';

function ListBus() {
  const [Bus, setBus] = useState([]);
  const [TrayekHalte, setTrayekHalte] = useState([]);
  const [SelectOption, setSelectOption] = useState([]);
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');
  const [bus_id, setBusId] = useState('');
  const [nama, setNama] = useState('');
  const [trayek_id, setTrayekId] = useState('');
  const [nopol, setNopol] = useState('');
  const [no_simcard, setNoSimcard] = useState('');
  useEffect(() => {
    getBus();
    getTrayek();
  }, []);

  // ambil data bus dari backend
  const getBus = async () => {
    const response = await axios.get(`/bus?page=${currentPage}&limit=${limit}`);
    setBus(response.data[0]);
    setTotal(Math.ceil(response.data[1].jumlah / limit));
  }

  // ambil data trayek dari backend
  const getTrayek = async () => {
    const response = await axios.get('/trayek');
    const options = response.data.map(d => ({
      "value": d.trayek_id,
      "label": d.nama
    }))
    setSelectOption(options)
  }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/bus/cari/?page=${currentPage}&limit=${limit}&key=${key}`);
      setBus(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    } else {
      const response = await axios.get(`/bus?page=${currentPage}&limit=${limit}`);
      setBus(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    }
  }

  const Pagination = async (page) => {
    setCurrentPage(page);
    getBus();
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <li className={currentPage === i ? 'page-item active' : 'page-item'}><button class="page-link" onClick={() => Pagination(i)}>{i}</button></li>
    );
  }

  // tambah data bus
  const Tambah = async (e) => {
    e.preventDefault();
    await axios.post('/bus', {
      trayek_id: trayek_id,
      nopol: nopol,
      no_simcard: no_simcard
    });
    await axios.get(process.env.REACT_APP_REFRESH)
    getBus();
    setShowAdd(false);
  }

  // edit data bus
  const Edit = async (e) => {
    e.preventDefault();
    await axios.put(`/bus/${bus_id}`, {
      trayek_id: trayek_id,
      nopol: nopol,
      no_simcard: no_simcard
    });
    await axios.get(process.env.REACT_APP_REFRESH)
    getBus();
    setShowEdit(false);
  }

  // hapus data bus
  const Hapus = (bus_id) => {
    swal({
      title: "Apakah Anda yakin akan menghapus data ini ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.delete('/bus/' + bus_id)
          swal("Data Halte Sukses dihapus", {
            icon: "success",
          })
          getBus();
        } else {
          swal("Data tidak terhapus");
        }
      });
  }

  // handle tambah data bus
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);

  // handle edit data bus
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
    setBusId('');
    setTrayekId('');
    setNopol('');
    setNoSimcard('');
  }

  const handleShowEdit = (data) => {
    setShowEdit(true);
    setBusId(data.bus_id);
    setTrayekId(data.trayek_id);
    setNopol(data.nopol);
    setNoSimcard(data.no_simcard);
  }

  // handle detail data bus
  const [showDetail, setShowDetail] = useState(false);
  const handleCloseDetail = () => {
    setShowDetail(false);
    setBusId('');
    setNopol('');
    setNama('');
  }
  const handleShowDetail = async (data) => {
    const response = await axios.get(`/trayek_halte/trayek/${data.trayek_id}`);
    const halte = response.data[0].nama_halte
    const dataHalte = halte.split(',');
    setTrayekHalte(dataHalte);
    setShowDetail(true);
    setBusId(data.bus_id);
    setNopol(data.nopol);
    setNama(data.nama);
  }

  return (
    <div className="container-xl">
      <h1 className="teks-hitam">Daftar Bus</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Beranda</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Daftar Bus
          </li>
        </ol>
      </nav>
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="d-flex justify-content-between">
              <div>
                <Button onClick={handleShowAdd} className="btn btn-success" data-toggle="modal"><i className="fas fa-plus"></i> <span>Tambah Bus&ensp;</span></Button>
              </div>
              <div>
                <Form onSubmit={filter} className="d-flex justify-content-end">
                  <Form.Control style={{ width: '15rem' }} value={key} className="me-2" onChange={(e) => setKey(e.target.value)} type="text" placeholder="Pencarian" aria-label="Search" />
                  <Button className="btn btn-outline-success" type="submit">Cari</Button>
                </Form>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover">
            <thead>
              <tr className='text-center'>
                <th>ID Bus</th>
                <th>Trayek Bus</th>
                <th>Plate Nomor</th>
                <th>Nomor SimCard</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {!!Bus && Bus ? (
                Bus.map((item, i) => (
                  <tr key={item.bus_id} className='text-center'>
                    <td>{i + 1}</td>
                    <td>{item.nama}</td>
                    <td>{item.nopol}</td>
                    <td>{item.no_simcard}</td>
                    <td>
                      <Button onClick={() => handleShowDetail(item)} className="detail" data-toggle="modal"><i className="fas fa-info-circle"></i> Detail</Button>
                      <Button onClick={() => handleShowEdit(item)} className="edit" data-toggle="modal"><i className="fas fa-edit" data-toggle="tooltip"></i> Edit</Button>
                      <Button onClick={() => Hapus(item.bus_id)} className="delete" data-toggle="modal"><i className="fas fa-trash" data-toggle="tooltip"></i> Hapus</Button>
                    </td>
                  </tr>
                ))) : (<div>Tidak ada data</div>)}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                <button class="page-link" aria-label="Previous" onClick={() => Pagination(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {pageNumbers}
              <li className={currentPage === Total ? 'page-item disabled' : 'page-item'}>
                <button class="page-link" aria-label="Next" onClick={() => Pagination(currentPage + 1)}>
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        {/* modal tambah bus */}
        <Modal show={showAdd} onHide={handleCloseAdd} animation={true} className="modal-crud">
          <Form onSubmit={Tambah} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-bus"></i></span>
                <span className="crud-judul">Tambah</span><span className="crud-header"> Bus</span>
              </Modal.Title>
              <Button onClick={handleCloseAdd} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Plate Nomor</Form.Label>
                <Form.Control type="text" placeholder='Plate Number' value={nopol} onChange={(e) => setNopol(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Nomor SimCard</Form.Label>
                <Form.Control type="text" placeholder="SimCard Number" value={no_simcard} onChange={(e) => setNoSimcard(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Rute</Form.Label>
                <Select options={SelectOption} onChange={(e) => setTrayekId(e.value)} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseAdd} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* modal edit bus */}
        <Modal show={showEdit} onHide={handleCloseEdit} animation={true} className="modal-crud">
          <Form onSubmit={Edit} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-bus"></i></span>
                <span className="crud-judul"> Edit</span><span className="crud-header"> Bus</span>
              </Modal.Title>
              <Button onClick={handleCloseEdit} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Plate Nomor</Form.Label>
                <Form.Control type="text" placeholder='Plate Nomor' value={nopol} onChange={(e) => setNopol(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Nomor SimCard</Form.Label>
                <Form.Control type="text" placeholder="Nomor SimCard" value={no_simcard} onChange={(e) => setNoSimcard(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Rute</Form.Label>
                <Select options={SelectOption} defaultValue={SelectOption.filter(({ value }) => value === trayek_id)} onChange={(e) => setTrayekId(e.value)} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseEdit} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal size="xl" show={showDetail} onHide={handleCloseDetail} animation={true} className="modal-crud">
          <Form className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-bus"></i></span>
                <span className="crud-judul"> Detail</span><span className="crud-header"> Bus</span>
              </Modal.Title>
              <Button onClick={handleCloseDetail} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <table className="table table-sm">
                <tbody>
                  <tr>
                    <td>ID Bus</td>
                    <td>:</td>
                    <td>{bus_id}</td>
                  </tr>
                  <tr>
                    <td>Plate Nomor</td>
                    <td>:</td>
                    <td>{nopol}</td>
                  </tr>
                  <tr>
                    <td>Rute Bus</td>
                    <td>:</td>
                    <td>{nama}</td>
                  </tr>
                </tbody>
              </table>
              <h3 className="text-center pb-3">Rute Bus {nama}</h3>
              <ul className="ratings">
                {!!TrayekHalte && TrayekHalte ? (
                  TrayekHalte.map((data) => {
                    return (
                      <li>{data}</li>
                    )
                  })) : (null)}
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseDetail} variant="danger">Batal</Button>
              <Button variant="primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default ListBus;