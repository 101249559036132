import React from 'react';
import Header from '../component/template/Header';
import Profil from '../component/user/profile';
import Footer from '../component/template/Footer';

function Profile() {
  return (
    <div>
      <Header />
      <div className="content">
        <div className="tinggi-content">
          <Profil />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Profile;