import React from 'react';
import Print from '../component/tracking/printDetail';

function PrintDetail() {
  return (
      <div>
        <Print />
      </div>
  );
}

export default PrintDetail;