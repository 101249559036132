import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tgl } from '../template/waktu';
import QRCode from "react-qr-code";
import './jadwal.css';

function DetailHalte() {
  const [Bus, setBus] = useState([]);
  const [pageURL, setPageURL] = useState(0);
  useEffect(() => {
    setPageURL(window.location.href);
    getBus();
  }, []);

  // ambil data bus
  const getBus = async () => {
    const response = await axios.get('/bus');
    setBus(response.data);
  }

  return (
    <div className="container">
      <table className='print-table'>
        <thead>
          <tr className='garis-bawah'>
            <th className="text-center"><img src={require(`../../assets/image/ptis.png`)} alt="logo" className='print-logo' /></th>
            <th className="text-center spasi">
              <h4 className='text-bold spasi'>Public Transport Information System</h4>
              <h5 className='text-bold spasi'>Laporan Jadwal Keberangkatan Bus</h5>
              <h6>Jl. Elang Jawa No.12, Wedomartani, Kec. Ngemplak, Kab. Sleman, D.I. Yogyakarta (55584)</h6>
            </th>
            <th className="text-center"><img src={require(`../../assets/image/javis.png`)} alt="logo" className='print-javis' /></th>
            <th className="text-center"><img src={require(`../../assets/image/dishub.png`)} alt="logo" className='print-javis' /></th>
          </tr>
        </thead>
      </table>
      <div className='row'>
        <div>
          <p className='float-end'> <b>Waktu Cetak Dokumen </b> : <Tgl /></p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <h4 className='text-center text-bold'>Jadwal Bus</h4>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Trayek Bus</th>
                <th>Nomor Kendaraan</th>
                <th>Halte Keberangkatan</th>
                <th>Halte Tujuan</th>
                <th>Estimasi Sampai</th>
              </tr>
            </thead>
            <tbody>
              {Bus ? Bus.map((data) => {
                return (
                  <tr key={data.bus_id}>
                    <td>{data.nama}</td>
                    <td>{data.nopol}</td>
                    <td>{data.dari}</td>
                    <td>{data.tujuan}</td>
                    <td>{data.waktu}</td>
                  </tr>
                )
              }) : ("Tidak ada jadwal perjalanan")}
            </tbody>
          </table>
        </div>
      </div>

      {/* Footer */}
      <hr className="mt-3" />
      <div className="row">
        <div className="col-6 col-sm-8 col-md-10">
          <h6>Copyright © PT. Javis Teknologi Albarokah</h6>
          <p className="ctext_10"><b>Cetak Dokumen </b> : <Tgl /></p>
        </div>
        <div className="col-6 col-sm-4 col-md-2 pe-3 pb-3 d-flex justify-content-end">
          <QRCode
            size={100}
            style={{ height: "auto", maxWidth: "100%" }}
            value={pageURL}
          />
        </div>
      </div>
    </div>
  )
}

export default DetailHalte;