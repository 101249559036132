import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import swal from 'sweetalert';

function Log() {
  const [Logs, setLog] = useState([]);
  const [mulai, setMulai] = useState();
  const [akhir, setAkhir] = useState();
  const [key, setKey] = useState('');
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  useEffect(() => {
    getLog();
  }, []);

  // modal hapus per tanggal
  const [showTgl, setShowTgl] = useState(false);
  const handleCloseTgl = () => setShowTgl(false);
  const handleShowTgl = () => setShowTgl(true);


  // ambil data log aktivitas dari backend
  const getLog = async () => {
    const response = await axios.get(`/user_log?page=${currentPage}&limit=${limit}`);
    setLog(response.data[0]);
    setTotal(Math.ceil(response.data[1].jumlah / limit));
  }

  // handle pagination
  const Pagination = async (page) => {
    setCurrentPage(page);
    getLog();
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <li className={currentPage === i ? 'page-item active' : 'page-item'}><button className="page-link" onClick={() => Pagination(i)}>{i}</button></li>
    );
  }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/user_log/cari?page=${currentPage}&limit=${limit}&key=${key}`);
      setLog(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    } else {
      const response = await axios.get(`/user_log?page=${currentPage}&limit=${limit}`);
      setLog(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    }
  }


  // hapus log
  const Hapus = (user_log_id) => {
    swal({
      title: "Apakah Anda yakin akan menghapus data ini ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.delete('/user_log/' + user_log_id)
          swal("Data Log Berhasil dihapus", {
            icon: "success",
          })
          setLog(Logs.filter((data) => data.user_log_id !== user_log_id));
        } else {
          swal("Data tidak terhapus");
        }
      });
  }

  // hapus log berdasarkan tanggal
  const HapusperTanggal = (e) => {
    e.preventDefault();
    swal({
      title: "Apakah Anda yakin akan menghapus data ini ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.post('/user_log/hapus', {
            mulai: mulai + ' 00:00:00',
            akhir: akhir + ' 23:59:59'
          })
          swal("Data Log Berhasil dihapus", {
            icon: "success",
          })
          setShowTgl(false);
          getLog();
        } else {
          swal("Data tidak terhapus");
        }
      });
  }

  return (
    <div className="container-xl">
      <h1 className="teks-hitam">Log Aktivitas</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Beranda</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Log Aktivitas
          </li>
        </ol>
      </nav>
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <h2>Log <b>Aktivitas</b></h2>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <button type="button" onClick={handleShowTgl} className="btn btn-danger" data-toggle="modal">Hapus Log</button>
            </div>
            <div>
              <Form onSubmit={filter} className="d-flex justify-content-end">
                <Form.Control style={{ width: '15rem' }} value={key} className="me-2" onChange={(e) => setKey(e.target.value)} type="text" placeholder="Pencarian" aria-label="Search" />
                <Button className="btn btn-outline-success" type="submit">Cari</Button>
              </Form>
            </div>
          </div>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama User</th>
                <th>Aktivitas</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {!!Logs && Logs ? (
                Logs.map((item, i) => (
                  <tr key={item.user_log_id}>
                    <td>{i + 1}</td>
                    <td>{item.nama_lengkap}</td>
                    <td>{item.log}</td>
                    <td>
                      <button onClick={() => Hapus(item.user_log_id)} className="delete" data-toggle="modal"><i className="fas fa-trash" data-toggle="tooltip"></i> Hapus</button>
                    </td>
                  </tr>
                ))) : (<div>Tidak ada data</div>)}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                <button className="page-link" aria-label="Previous" onClick={() => Pagination(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {pageNumbers}
              <li className={currentPage === Total ? 'page-item disabled' : 'page-item'}>
                <button className="page-link" aria-label="Next" onClick={() => Pagination(currentPage + 1)}>
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        {/* modal hapus berdasarkan tanggal */}
        <Modal show={showTgl} onHide={handleCloseTgl} animation={true} className="modal-crud">
          <Form onSubmit={HapusperTanggal} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-bus"></i></span>
                <span className="crud-judul"> Hapus</span><span className="crud-header"> Log</span>
              </Modal.Title>
              <Button onClick={handleCloseTgl} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Tanggal Mulai</Form.Label>
                <Form.Control type="date" placeholder='Plate Number' value={mulai} onChange={(e) => setMulai(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Tanggal Akhir</Form.Label>
                <Form.Control type="date" placeholder="SimCard Number" value={akhir} onChange={(e) => setAkhir(e.target.value)} required />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseTgl} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Delete Log</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default Log;