import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import kemhub from '../../assets/image/kemhub.png';

function Header() {
  // logout
  const logOut = () => {
    localStorage.removeItem('x-access-token');
    localStorage.removeItem('x-access-token-expiration');
    localStorage.removeItem('id_user');
  }
  const id_user = localStorage.getItem('id_user');
  return (
    <div id="page-top">
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="shadow bg-body rounded" id="mainNav">
        <Container>
          <a className="navbar-brand fw-bold" href='/Home'>
            <img src={kemhub} style={{ height: "50px" }} alt="Ptis" />
          </a>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto" />
            <Nav className="ml-auto nav-pills" >
              <NavLink to="/dashboard" className={({ isActive }) => isActive ? 'nav-operator me-lg-3 aktif' : 'nav-operator me-lg-3'}><i className="fas fa-home" /> Beranda</NavLink>
              <NavLink to="/jadwal" className={({ isActive }) => isActive ? 'nav-operator me-lg-3 aktif' : 'nav-operator me-lg-3'}><i className="fas fa-calendar-week" /> Jadwal Bus</NavLink>
              <NavLink to="/tracking" className={({ isActive }) => isActive ? 'nav-operator me-lg-3 aktif' : 'nav-operator me-lg-3'}><i className="fas fa-map-marked-alt" /> Lacak Bus</NavLink>
              <NavDropdown title={<i className="fas fa-cogs"> Kelola Data</i>} id="basic-nav-dropdown">
                <NavDropdown.Item href="/list-bus"><i className="fas fa-bus" /> Data Bus</NavDropdown.Item>
                <NavDropdown.Item href="/list-halte"><i className="fas fa-store-alt" /> Data Halte</NavDropdown.Item>
                <NavDropdown.Item href="/list-trayek"><i className="fas fa-route" /> Data Trayek</NavDropdown.Item>
                <NavDropdown.Item href="/list-display"><i className="far fa-building" /> Konten Display</NavDropdown.Item>
                <NavDropdown.Item href="/list-user"><i className="fas fa-user-cog" /> User</NavDropdown.Item>
                <NavDropdown.Item href="/list-grup"><i className="fas fa-users" /> Grup User</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={<i className="fas fa-user fa-fw" ></i>} id="collasible-nav-dropdown">
                <NavDropdown.Item href={`/profile/${id_user}`}><i className="fas fa-user-tie" /> Akun</NavDropdown.Item>
                <NavDropdown.Item href="/log-aktivitas"><i className="fas fa-user-clock" /> Log Aktivitas</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logOut} href="/Login"><i className="fas fa-user-lock" /> Keluar</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;