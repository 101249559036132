import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import swal from 'sweetalert';
import axios from 'axios';

function ListTrayek() {
  const [Trayek, setTrayek] = useState([]);
  const [SelectOption, setSelectOption] = useState([]);
  const [TrayekHalte, setTrayekHalte] = useState([]);
  const [dataTrayekHalte, setDataTrayekHalte] = useState([]);
  const [trayek_id, setTrayekId] = useState('');
  const [nama, setNama] = useState('');
  const animatedComponents = makeAnimated();
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');

  useEffect(() => {
    getTrayek();
    getHalte();
  }, []);

  // ambil data trayek
  const getTrayek = async () => {
    const response = await axios.get(`/trayek?page=${currentPage}&limit=${limit}`);
    setTrayek(response.data[0]);
    setTotal(Math.ceil(response.data[1].jumlah / limit));
  }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/trayek/cari/?page=${currentPage}&limit=${limit}&key=${key}`);
      setTrayek(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    } else {
      const response = await axios.get(`/trayek?page=${currentPage}&limit=${limit}`);
      setTrayek(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    }
  }

  // handle pagination
  const Pagination = async (page) => {
    setCurrentPage(page);
    getTrayek();
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <li className={currentPage === i ? 'page-item active' : 'page-item'}><button className="page-link" onClick={() => Pagination(i)}>{i}</button></li>
    );
  }

  // ambil data halte
  const getHalte = async () => {
    const response = await axios.get('/halte');
    const options = response.data.map(d => ({
      "value": d.halte_id,
      "label": d.nama
    }))
    setSelectOption(options)
  }

  // tambah trayek
  const Tambah = async (e) => {
    e.preventDefault();
    const response = await axios.post('/trayek', {
      nama: nama
    });
    const id_trayek = response.data.trayek_id;
    for (let i = 0; i < dataTrayekHalte.length; i++) {
      axios.post('/trayek_halte', {
        trayek_id: id_trayek,
        halte_id: dataTrayekHalte[i].value
      });
    }
    await axios.get(process.env.REACT_APP_REFRESH)
    getTrayek();
    setShowAdd(false);
  }

  // edit trayek
  const Edit = async (e) => {
    e.preventDefault();
    axios.delete(`/trayek_halte/${trayek_id}`);
    for (let i = 0; i < dataTrayekHalte.length; i++) {
      axios.post('/trayek_halte', {
        trayek_id: trayek_id,
        halte_id: dataTrayekHalte[i].value
      });
    }
    await axios.get(process.env.REACT_APP_REFRESH)
    getTrayek();
    setShowEdit(false);
  }

  // hapus trayek
  const Hapus = async (trayek_id) => {
    swal({
      title: "Apakah Anda yakin akan menghapus data ini ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.delete('/trayek/' + trayek_id)
          swal("Data Halte Sukses dihapus", {
            icon: "success",
          })
          getTrayek();
        } else {
          swal("Data tidak terhapus");
        }
      });
    await axios.get(process.env.REACT_APP_REFRESH)
  }

  // handle modal tambah
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);

  // handle modal edit
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
    setTrayekId('')
    setNama('')
  }
  const handleShowEdit = (data) => {
    setShowEdit(true);
    setTrayekId(data.trayek_id);
    setNama(data.nama);
  }

  // handle modal detail
  const [showDetail, setShowDetail] = useState(false);
  const handleCloseDetail = () => {
    setShowDetail(false);
    setTrayekId('');
    setNama('');
    setTrayekHalte('')
  }
  const handleShowDetail = async (data) => {
    const response = await axios.get(`/trayek_halte/trayek/${data.trayek_id}`);
    const halte = response.data[0].nama_halte
    const dataHalte = halte.split(',');
    setTrayekHalte(dataHalte);
    setShowDetail(true);
    setTrayekId(data.trayek_id);
    setNama(data.nama);
  }

  return (
    <div className="container-xl">
      <h1 className="teks-hitam">Daftar Trayek</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Beranda</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Daftar Trayek
          </li>
        </ol>
      </nav>
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="d-flex justify-content-between">
              <div>
                <Button onClick={handleShowAdd} className="btn btn-success" data-toggle="modal"><i className="fas fa-plus"></i> <span>Tambah Rute&ensp;</span></Button>
              </div>
              <div>
                <Form onSubmit={filter} className="d-flex justify-content-end">
                  <Form.Control style={{ width: '15rem' }} value={key} className="me-2" onChange={(e) => setKey(e.target.value)} type="text" placeholder="Pencarian" aria-label="Search" />
                  <Button className="btn btn-outline-success" type="submit">Cari</Button>
                </Form>
              </div>
            </div>
          </div>
          <table responsive='sm' className="table table-striped table-hover">
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama Rute</th>
                <th className='text-center'>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {!!Trayek && Trayek ? (
                Trayek.map((item, i) => (
                  <tr key={item.trayek_id}>
                    <td>{i + 1}</td>
                    <td>{item.nama}</td>
                    <td className='text-center'>
                      <Button onClick={() => handleShowDetail(item)} className="detail" data-toggle="modal"><i className="fas fa-info-circle"></i> Detail</Button>
                      <Button onClick={() => handleShowEdit(item)} className="edit" data-toggle="modal" data-target="#editTrayek"><i className="fas fa-edit" data-toggle="tooltip"></i> Edit</Button>
                      <Button onClick={() => Hapus(item.trayek_id)} className="delete" data-toggle="modal"><i className="fas fa-trash" data-toggle="tooltip"></i> Hapus</Button>
                    </td>
                  </tr>
                ))) : (<div>Tidak ada data</div>)}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                <button className="page-link" aria-label="Previous" onClick={() => Pagination(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {pageNumbers}
              <li className={currentPage === Total ? 'page-item disabled' : 'page-item'}>
                <button className="page-link" aria-label="Next" onClick={() => Pagination(currentPage + 1)}>
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        {/* modal tambah trayek */}
        <Modal show={showAdd} onHide={handleCloseAdd} animation={true} className="modal-crud">
          <Form onSubmit={Tambah} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-route"></i></span>
                <span className="crud-judul"> Tambah</span><span className="crud-header"> Rute</span>
              </Modal.Title>
              <Button onClick={handleCloseAdd} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Nama Rute</Form.Label>
                <Form.Control type="text" placeholder='Trayek Name' value={nama} onChange={(e) => setNama(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Rute Halte</Form.Label>
                <Select closeMenuOnSelect={false} components={animatedComponents} isMulti options={SelectOption} onChange={(e) => setDataTrayekHalte(e)} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseAdd} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* modal edit trayek */}
        <Modal show={showEdit} onHide={handleCloseEdit} animation={true} className="modal-crud">
          <Form onSubmit={Edit} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-route"></i></span>
                <span className="crud-judul"> Edit</span><span className="crud-header"> Rute</span>
              </Modal.Title>
              <Button onClick={handleCloseEdit} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Nama Rute</Form.Label>
                <Form.Control type="text" placeholder='Nama Rute' value={nama} onChange={(e) => setNama(e.target.value)} required />
              </Form.Group>
              <Form.Group>
                <Form.Label className="crud-label">Rute Halte</Form.Label>
                <Select closeMenuOnSelect={false} components={animatedComponents} isMulti options={SelectOption} onChange={(e) => setDataTrayekHalte(e)} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseEdit} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* modal detail trayek */}
        <Modal size="xl" show={showDetail} onHide={handleCloseDetail} animation={true} className="modal-crud">
          <Form className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-route"></i></span>
                <span className="crud-judul"> Lihat</span><span className="crud-header"> Rute</span>
              </Modal.Title>
              <Button onClick={handleCloseDetail} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <h4 className="text-center pb-3">Rute {nama}</h4>
              <ul className="ratings">
                {!!TrayekHalte && TrayekHalte ? (
                  TrayekHalte.map((data) => {
                    return (
                      <li>{data}</li>
                    )
                  })) : (null)}
              </ul>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
              <Button onClick={handleCloseDetail} className="btn btn-danger">Batal</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default ListTrayek;