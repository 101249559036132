import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import swal from 'sweetalert';

function ListUserGroup() {
  const [UserGroup, setUserGroup] = useState([]);
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  const [key, setKey] = useState('');
  useEffect(() => {
    getUserGroup();
  }, []);

  // ambil data grup user
  const getUserGroup = async () => {
    const response = await axios.get(`/user_group?page=${currentPage}&limit=${limit}`);
    setUserGroup(response.data[0]);
    setTotal(Math.ceil(response.data[1].jumlah / limit));
  }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/user_group/cari/?page=${currentPage}&limit=${limit}&key=${key}`);
      setUserGroup(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    } else {
      const response = await axios.get(`/user_group?page=${currentPage}&limit=${limit}`);
      setUserGroup(response.data[0]);
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    }
  }

  // handle pagination
  const Pagination = async (page) => {
    setCurrentPage(page);
    getUserGroup();
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <li className={currentPage === i ? 'page-item active' : 'page-item'}><button className="page-link" onClick={() => Pagination(i)}>{i}</button></li>
    );
  }

  const [user_group_id, setUserGroupId] = useState('');
  const [nama, setNama] = useState('');


  // tambah grup user
  const Tambah = async (e) => {
    e.preventDefault();
    await axios.post('/user_group', {
      nama: nama
    });
    getUserGroup();
    setShowAdd(false);
  }

  // edit grup user
  const Edit = async (e) => {
    e.preventDefault();
    await axios.put(`/user_group/${user_group_id}`, {
      user_group_id: user_group_id,
      nama: nama
    });
    getUserGroup();
    setShowEdit(false);
  }

  // hapus grup user
  const Hapus = (user_group_id) => {
    swal({
      title: "Apakah Anda yakin akan menghapus data ini ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.delete('/user_group/' + user_group_id)
          swal("Data Halte Sukses dihapus", {
            icon: "success",
          })
          getUserGroup();
        } else {
          swal("Data tidak terhapus");
        }
      });
  }

  // handle modal tambah
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);

  // handle modal edit
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
    setUserGroupId('');
    setNama('');
  }
  const handleShowEdit = (data) => {
    setShowEdit(true);
    setUserGroupId(data.user_group_id);
    setNama(data.nama);
  }

  return (
    <div className="container-xl">
      <h1 className="teks-hitam">Daftar User Grup</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Beranda</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Daftar User Grup
          </li>
        </ol>
      </nav>
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="d-flex justify-content-between">
              <div>
                <Button onClick={handleShowAdd} className="btn btn-success" data-toggle="modal"><i className="fas fa-plus"></i> <span>Tambah Grup User&ensp;</span></Button>
              </div>
              <div>
                <Form onSubmit={filter} className="d-flex justify-content-end">
                  <Form.Control style={{ width: '15rem' }} value={key} className="me-2" onChange={(e) => setKey(e.target.value)} type="text" placeholder="Pencarian" aria-label="Search" />
                  <Button className="btn btn-outline-success" type="submit">Cari</Button>
                </Form>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama Grup User</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {!!UserGroup && UserGroup ? (
                UserGroup.map((item, i) => (
                  <tr key={item.user_group_id}>
                    <td>{i + 1}</td>
                    <td>{item.nama}</td>
                    <td>
                      <Button onClick={() => handleShowEdit(item)} className="edit" data-toggle="modal"><i className="fas fa-edit" data-toggle="tooltip"></i> Edit</Button>
                      <Button onClick={() => Hapus(item.user_group_id)} className="delete" data-toggle="modal"><i className="fas fa-trash" data-toggle="tooltip"></i> Hapus</Button>
                    </td>
                  </tr>
                ))) : (<div>Tidak ada data</div>)}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                <button className="page-link" aria-label="Previous" onClick={() => Pagination(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {pageNumbers}
              <li className={currentPage === Total ? 'page-item disabled' : 'page-item'}>
                <button className="page-link" aria-label="Next" onClick={() => Pagination(currentPage + 1)}>
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        {/* modal tambah user */}
        <Modal show={showAdd} onHide={handleCloseAdd} animation={true} className="modal-crud">
          <Form onSubmit={Tambah} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-bus"></i></span>
                <span className="crud-judul"> Tambah</span><span className="crud-header"> Grup User</span>
              </Modal.Title>
              <Button onClick={handleCloseAdd} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Nama Grup</Form.Label>
                <Form.Control type="text" placeholder="Nama Grup" value={nama} onChange={(e) => setNama(e.target.value)} required />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseAdd} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* modal edit user */}
        <Modal show={showEdit} onHide={handleCloseEdit} animation={true} className="modal-crud">
          <Form onSubmit={Edit} className="form-box">
            <Modal.Header className="header-modal">
              <Modal.Title>
                <span className="crud-icon"><i className="fas fa-users"></i></span>
                <span className="crud-judul"> Edit</span><span className="crud-header"> Grup User</span>
              </Modal.Title>
              <Button onClick={handleCloseEdit} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
            </Modal.Header>
            <Modal.Body className="form-crud">
              <Form.Group>
                <Form.Label className="crud-label">Nama Grup</Form.Label>
                <Form.Control type="text" placeholder="Nama Grup" value={nama} onChange={(e) => setNama(e.target.value)} required />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button onClick={handleCloseEdit} className="btn btn-danger">Batal</Button>
              <Button variant="btn btn-primary" type="submit">Simpan</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default ListUserGroup;