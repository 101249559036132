import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from 'sweetalert';

function Setting() {
    const [websocket, setWebsocket] = useState('');
    const [mqtt, setMqtt] = useState('');
    useEffect(() => {
        getData();
    }, []);

    // ambil data dari backend
    const getData = async () => {
        const response = await axios.get("/setting");
        setWebsocket(response.data.websocket);
        setMqtt(response.data.mqtt);
    }

    // edit data ip
    const Edit = async (e) => {
        e.preventDefault();
        swal({
            title: "Apakah Anda yakin akan mengubah ip websocket?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((berhasil) => {
            if (berhasil) {
                axios.post('/setting', { websocket: websocket, mqtt: mqtt })
                swal("berhasil diubah", {
                    icon: "success",
                })
                getData();
            } else {
                swal("tidak ada perubahan data");
            }
        });
    }

    return (
        <div className="container-xl">
            <h1 className="teks-hitam">Setting</h1>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="/">Beranda</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Setting
                    </li>
                </ol>
            </nav>
            <div className="card tinggi-setting">
                <div className="card-body d-flex justify-content-center">
                    <form method="post" onSubmit={Edit} >
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label teks-hitam">
                                <b>Websocket</b>
                            </label>
                            <input type="text" className="form-control" placeholder="Websocket server" value={websocket} onChange={(e) => setWebsocket(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label teks-hitam">
                                <b>MQTT</b>
                            </label>
                            <input type="text" className="form-control" placeholder="MQTT server" value={mqtt} onChange={(e) => setMqtt(e.target.value)} required />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Simpan
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}



export default Setting;

