import React, { useState } from 'react';
import Javis from '../../assets/image/javis.png';
import Dishub from '../../assets/image/dishub.png';
import { Form, Button } from 'react-bootstrap';
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { Input, InputAdornment, IconButton } from "@material-ui/core";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function ResetPassword() {
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
    showKPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowKPassword = () => {
    setValues({ ...values, showKPassword: !values.showKPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const Auth = async (e) => {
    e.preventDefault();
    if (confPassword === password) {
      await axios.post('/UpdatePassword', {
        password: password
      });
      navigate('/Login');
    } else {
      setMsg("Password Tidak Sama!!");
    }
  }

  return (
    <div className='posisi-tengah'>
      <div className='konten-lupa-password card'>
        <h3 className="header-title text-center">Ganti Password</h3>
        {msg ? (
          <h4>{msg}</h4>
        ) : ('')}
        <Form onSubmit={Auth}>
          <Form.Group className="mb-2 mt-3">
            <Form.Label>Password</Form.Label>
            <Input className="form-control" id="inputPassword" placeholder="Password"
              type={values.showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              } />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Konfirmasi Password</Form.Label>
            <Input className="form-control" id="inputPasswordConfirm" placeholder="Konfirmasi password"
              type={values.showKPassword ? "text" : "password"}
              value={confPassword}
              onChange={(e) => setConfPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowKPassword}
                    onMouseDown={handleMouseDownPassword} >
                    {values.showKPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              } />
          </Form.Group>
          <div className="mt-4">
            <Button type="submit" className="btn btn-primary">Kirim</Button>
          </div>
        </Form>
        <div className="small text-center">Already Have an account? <a href="Login">Sign in!</a></div>
      </div>
      <div className='d-flex justify-content-center mt-2'>
        <img src={Javis} style={{ width: "40px", height: '40px' }} alt="Javis" />
        <img src={Dishub} style={{ width: "40px", height: '40px' }} alt="Dishub" />
      </div>
      <div className='d-flex justify-content-center'>
        <p className='text-center text-white'>Dikembangkan oleh: <br /> PT. Javis Teknologi Albarokah</p>
      </div>
    </div>
  );
}

export default ResetPassword;