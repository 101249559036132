import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import { Tanggal, Jam, Hari, Gambar } from '../template/waktu';
import JadwalMap from './jadwalMap';
import './jadwal.css';

function Jadwal() {
  const [Bus, setBus] = useState([]);
  const [Total, setTotal] = useState(1); // total halaman
  const [currentPage, setCurrentPage] = useState(1); // halaman yang aktif
  const limit = 20; // jumlah item per halaman
  var arrayBus = [];
  useEffect(() => {
    getBus();
    connect_socket();
  }, []);

  // lihat lokasi
  const [lihatBus, setLihatBus] = useState();
  const [Trayek, setTrayek] = useState([]);
  const [key, setKey] = useState('');
  const [lihatLokasi, setLihatLokasi] = useState(false);
  const handleTutupLokasi = () => setLihatLokasi(false);
  const handleLihatLokasi = async (data) => {
    const response = await axios.get('/trayek_halte/trayek/' + data.trayek_id);
    setTrayek(response.data);
    setLihatBus(data);
    setLihatLokasi(true);
  }

  // filter data dalam pencarian
  const filter = async (e) => {
    e.preventDefault();
    if (key !== '') {
      const response = await axios.get(`/bus/cari/?page=${currentPage}&limit=${limit}&key=${key}`);
      setBus(response.data[0]);
      arrayBus = response.data[0];
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    } else {
      const response = await axios.get(`/bus?page=${currentPage}&limit=${limit}`);
      setBus(response.data[0]);
      arrayBus = response.data[0];
      setTotal(Math.ceil(response.data[1].jumlah / limit));
    }
  }

  // ambil data bus
  const getBus = async () => {
    const response = await axios.get(`/bus?page=${currentPage}&limit=${limit}`);
    setBus(response.data[0]);
    arrayBus = response.data[0];
    setTotal(Math.ceil(response.data[1].jumlah / limit));
  }

  // handle pagination
  const Pagination = async (page) => {
    setCurrentPage(page);
    getBus();
  }

  // membuat list item pagination
  const pageNumbers = [];
  for (let i = 1; i <= Total; i++) {
    pageNumbers.push(
      <li className={currentPage === i ? 'page-item active' : 'page-item'}><button className="page-link" onClick={() => Pagination(i)}>{i}</button></li>
    );
  }

  // koneksi websocket
  const connect_socket = () => {
    let sc = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
    sc.onmessage = function (ev) {
      console.log('ws message')
      try {
        var d = JSON.parse(ev.data);
        let cariBus = arrayBus.findIndex(dataBus => dataBus.bus_id === d.bus_id);
        console.log(cariBus);

        // jika data bus tidak kosong
        if (cariBus !== -1) {
          arrayBus[cariBus] = d;
          setBus(arrayBus);
        }
          if (lihatBus.bus_id === d.bus_id) {
            setLihatBus(d);
          }
      } catch (e) {
        console.log("coba lagi");
      }
    }
    sc.onopen = function (ev) {
      console.log('ws openned');
    }
    sc.onerror = function (ev) {
      console.log('ws error');
      console.log(ev);
      sc.close();
    }
    sc.onclose = function (ev) {
      console.log('ws closed');
      console.log(ev);
      setTimeout(function () {
        connect_socket();
      }, 1000);
    }
  }

  return (
    <div className='mt-5'>
      <div className="table-title">
        <div className="row">
          <div className="col-sm-6">
            <h2><i className="fas fa-calendar-week"></i> Jadwal <b>Bus</b></h2>
          </div>
          <div className="col-sm-6">
            <a href='/PrintJadwal' target="_blank" className="btn btn-primary"><i className="fas fa-print"></i><span>Cetak&ensp;</span></a>
          </div>
        </div>
      </div>
      <div className='row m-0 p-0'>
        <div className='col-md-5'>
          <div className='row'>
            <div className='col-2'>
              <Gambar />
            </div>
            <div className='col-10'>
              <h3 className="display-text"><Hari />,</h3>
              <h3 className="display-text"><Tanggal /></h3>
            </div>
            <h1 className="display-text"><Jam /></h1>
          </div>
        </div>
        <div className='col-md-7'>
          <Form onSubmit={filter} className="d-flex justify-content-end">
            <Form.Control style={{ width: '15rem' }} value={key} className="me-2" onChange={(e) => setKey(e.target.value)} type="text" placeholder="Pencarian" aria-label="Search" />
            <Button className="btn btn-outline-success" type="submit">Cari</Button>
          </Form>
        </div>
      </div>
      <div className="table-responsive">
        <div className="table-wrapper">
          <table id='jadwal' className='mb-3'>
            <thead>
              <tr>
                <th>No.</th>
                <th>Trayek Bus</th>
                <th>Halte Keberangkatan</th>
                <th>Halte Tujuan</th>
                <th>Estimasi Sampai</th>
                <th className='text-center'>Lokasi Bus</th>
              </tr>
            </thead>
            <tbody>
              {!!Bus && Bus ? (
                Bus.map((item, i) => (
                  <tr key={item.bus_id}>
                    <td>{i + 1}</td>
                    <td>{item.nama}</td>
                    <td>{item.dari}</td>
                    <td>{item.tujuan}</td>
                    <td>{item.waktu}</td>
                    <td className='d-flex justify-content-center'><button type="button" onClick={() => handleLihatLokasi(item)} className="btn btn-primary text-center">Lihat Lokasi</button></td>
                  </tr>
                ))) : (<div>Tidak ada data</div>)}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                <button className="page-link" aria-label="Previous" onClick={() => Pagination(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {pageNumbers}
              <li className={currentPage === Total ? 'page-item disabled' : 'page-item'}>
                <button className="page-link" aria-label="Next" onClick={() => Pagination(currentPage + 1)}>
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Modal lihat lokasi terkini */}
      <Modal size="lg" show={lihatLokasi} onHide={handleTutupLokasi} animation={true} className="modal-crud">
        <Form className="form-box">
          <Modal.Header className="header-modal">
            <Modal.Title>
              <span className="crud-icon"><i className="fas fa-store-alt" /></span>
              <span className="crud-judul"> Edit</span><span className="crud-header"> Halte</span>
            </Modal.Title>
            <Button onClick={handleTutupLokasi} type="button" className="silang" data-dismiss="modal" aria-hidden="true">&times;</Button>
          </Modal.Header>
          <Modal.Body className="form-crud">
            <Form.Group>
              <div className="modal-map">
                <JadwalMap bus={lihatBus} trayek={Trayek} />
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button onClick={handleTutupLokasi} className="btn btn-danger">Batal</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default Jadwal;