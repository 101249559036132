import React from 'react';
import Header from '../component/template/Header';
import Setting from '../component/setting/setting';
import Footer from '../component/template/Footer';

function SettingIp() {
    return (
        <div className='body'>
            <Header />
            <div className='content'>
                <div className='tinggi-content'>
                    <Setting />
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default SettingIp;