import React from 'react';
import './Loading.css';

function Loading() {
    return (
        <div className="loader-wrapper">
        <div className="truck-wrapper">
          <div className="truck">
            <div className="truck-container" />
            <div className="glases" />
            <div className="bonet" />
            <div className="base" />
            <div className="base-aux" />
            <div className="wheel-back" />
            <div className="wheel-front" />
            <div className="smoke" />
          </div>
        </div>
      </div>
    )
}

export default Loading;