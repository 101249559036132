import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { Context } from '../assets/css-js/context';
import Loading from '../assets/css-js/Loading';


export default function PrivateRoute(props) {
  const { isLoading } = useContext(Context);
  const auth = localStorage.getItem("x-access-token");

  if (isLoading) {
    return <Loading />
  }

  if (auth) {
    return <Outlet />
  } else {
    return <Navigate to={'/login'} />
  }
}