import React from 'react';
import Header from '../component/template/Header';
import Schedule from '../component/jadwal/jadwal';
import Footer from '../component/template/Footer';

function Jadwal() {
  return (
    <div>
      <Header />
      <div className="content">
        <div className="tinggi-content">
          <Schedule />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Jadwal;