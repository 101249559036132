import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import Halte from '../../assets/image/halte2.png';
import Bus from '../../assets/image/bus-tracker.png';
import { warna } from '../../assets/css-js/TemplateMap';

const containerStyle = {
  width: '100vw',
  height: '100vh'
};

var path = [
  {
    lat: 12.9802347063322,
    lng: 77.5907760360903,
    bearing: -20.5784744283754
  },
  {
    lat: 12.9793774204024,
    lng: 77.5910979011596,
    bearing: 17.1118088152409
  },
  {
    lat: 12.9795865148043,
    lng: 77.5911622741734,
    bearing: 70.6690312217414
  },
  {
    lat: 12.9797746996155,
    lng: 77.5916987159555,
    bearing: 38.1233134168197
  },
  {
    lat: 12.9801301594259,
    lng: 77.5919776656823,
    bearing: -45.7414247345699
  },
  {
    lat: 12.9798374278543,
    lng: 77.5922780730802,
    bearing: 16.0994201411847
  },
  {
    lat: 12.9791683258247,
    lng: 77.5920849540387,
    bearing: 35.6916527554558
  },
  {
    lat: 12.9787501361417,
    lng: 77.5917845466407,
    bearing: 58.0502467067782
  },
  {
    lat: 12.9784155838887,
    lng: 77.5912481048586,
    bearing: 64.0233912454979
  },
  {
    lat: 12.9784783124705,
    lng: 77.5913768508863,
    bearing: 45.7412428776673
  },
  {
    lat: 12.9783319457552,
    lng: 77.5912266471873,
    bearing: -69.926654677622
  },
  {
    lat: 12.978394674358,
    lng: 77.591054985817,
    bearing: 16.3413468751341
  },
  {
    lat: 12.9779555738058,
    lng: 77.5909262397893,
    bearing: 54.6749460887583
  },
  {
    lat: 12.9776210204837,
    lng: 77.5904541710211,
    bearing: 64.0233096712307
  },
  {
    lat: 12.9774746532636,
    lng: 77.5901537636231,
    bearing: 65.5464053454266
  },
  {
    lat: 12.9761573444059,
    lng: 77.5872569779997,
    bearing: -66.4029340594377
  },
  {
    lat: 12.9764291706147,
    lng: 77.5866347055324,
    bearing: -48.4630801907934
  },
  {
    lat: 12.9766382674962,
    lng: 77.5863986711483,
    bearing: -54.992843944921
  },
  {
    lat: 12.9771191896563,
    lng: 77.5857120256672,
    bearing: -60.0659370316888
  }
];

const velocity = 60;
const initialDate = new Date();

function Contoh(props) {
  const { lokasi, bus, halte, trayek } = props;
  const [map, setMap] = useState(null);
  const [progress, setProgress] = useState([]);
  const [ libraries ] = useState(["geometry", "drawing", "places"]);
  const [pilihHalte, setPilihHalte] = useState([]);
  const [pilihBus, setPilihBus] = useState([]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey:"AIzaSyCnTyLMTagAytZaQfW7pcZTFAd1p3NE7Bc",
    libraries:libraries
  });

  const intervalIdRef = useRef(null);
  useEffect(() => {
    if (isLoaded) {
      intervalIdRef.current = setInterval(() => {moveObject()}, 100);
      calculatePath();
    };

    if (trayek) {
      polyline()
    }
  }, []);

  // jika marker halte di-klik
  const onHalteClick = (dataHalte) => {
    setPilihHalte(dataHalte);
  };

  // jika marker bus di-klik
  const onBusClick = (dataBus) => {
    setPilihBus(dataBus);
  };

  const getDistance = () => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - initialDate) / 1000; // pass to seconds
    return differentInTime * velocity; // d = v*t
  };

  const moveObject = () => {
    const distance = getDistance();
    if (!distance) {
      return;
    }
    
    let progress = path.filter(
      (coordinates) => coordinates.distance < distance
    );
    
    const nextLine = path.find(
      (coordinates) => coordinates.distance > distance
    );

    if (!nextLine) {
      setProgress(progress);
      clearInterval(intervalIdRef.current);
      return; // it's the end!
    }
    const lastLine = progress[progress.length - 1];
    
    const lastLineLatLng = new window.google.maps.LatLng(
      lastLine.lat,
      lastLine.lng
    );
    
    const nextLineLatLng = new window.google.maps.LatLng(
      nextLine.lat,
      nextLine.lng
    );
    
    // distance of this line
    const totalDistance = nextLine.distance - lastLine.distance;
    const percentage = (distance - lastLine.distance) / totalDistance;
    
    const position = window.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    );
    
    setProgress(progress.concat(position));
    componentDidUpdate();
  };
  
  const calculatePath = () => {
    path = path.map((coordinates, i, array) => {
      if (i === 0) {
        return { ...coordinates, distance: 0 }; // it begins here!
      }
      const { lat: lat1, lng: lng1 } = coordinates;
      const latLong1 = new window.google.maps.LatLng(lat1, lng1);

      const { lat: lat2, lng: lng2 } = array[0];
      const latLong2 = new window.google.maps.LatLng(lat2, lng2);

      // in meters:
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        latLong1,
        latLong2
      );

      return { ...coordinates, distance };
    });
  };

  const polyline = () => {
    trayek.map((data, index) => {
      const lat_array = data.lat.split(',');
      const lng_array = data.lng.split(',');
      let wayPoints = [];
      //setting jalur bus
      var rendererOptions = {
        map: map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: warna[index]
        }
      }

      //panggil fungsi directions service dan render
      const directionsService = new window.google.maps.DirectionsService();
      const directionsDisplay = new window.google.maps.DirectionsRenderer(rendererOptions);

      //ambil data trayek dan masukkan di fungsi render
      directionsDisplay.setMap(map);

      //ambil data awal jalur
      let start = { lat: Number(lat_array[0]), lng: Number(lng_array[0]) };

      //ambil data akhir jalur
      let end = {
        lat: Number(lat_array[lat_array.length - 1]),
        lng: Number(lng_array[lng_array.length - 1]),
      };

      //ambil semua jalur pemberhentian
      for (let i = 1; i < lng_array.length - 1; i++) {
        wayPoints.push({
          location: { lat: Number(lat_array[i]), lng: Number(lng_array[i]) }
        });
      }

      // jalur pemberhentian
      let request = {
        origin: start,
        waypoints: wayPoints,
        destination: end,
        travelMode: "DRIVING",
      };

      //menampilkan data directionsrenderer
      directionsService.route(request, function (result, status) {
        if (status === window.google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(result);
        }
      });
    })
  }
    
  const componentDidUpdate = () => {
    const distance = getDistance();
    if (!distance) {
      return;
    }
    
    let progress = path.filter(
      (coordinates) => coordinates.distance < distance
    );
  
    const nextLine = path.find(
      (coordinates) => coordinates.distance > distance
    );
    
    let point1, point2;
    
    if (nextLine) {
      point1 = progress[progress.length - 1];
      point2 = nextLine;
    } else {
      // it's the end, so use the latest 2
      point1 = progress[progress.length - 2];
      point2 = progress[progress.length - 1];
    }
    
    const point1LatLng = new window.google.maps.LatLng(point1.lat, point1.lng);
    const point2LatLng = new window.google.maps.LatLng(point2.lat, point2.lng);
    
    const angle = window.google.maps.geometry.spherical.computeHeading(
      point1LatLng,
      point2LatLng
    );
    const actualAngle = angle - 90;
    
    const markerUrl = "https://images.vexels.com/media/users/3/154573/isolated/preview/bd08e000a449288c914d851cb9dae110-hatchback-car-top-view-silhouette-by-vexels.png";
    const marker = document.querySelector(`[src="${markerUrl}"]`);
    
    if (marker) {
      // when it hasn't loaded, it's null
      marker.style.transform = `rotate(${actualAngle}deg)`;
    }
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({ lat: -7.747872, lng: 110.4218147 });
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }

  return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={12}
        center={lokasi}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >

        {/* Marker Halte */}
        {halte ? (halte.map((place) => {
            return (
              <Marker
                onClick={() => onHalteClick(place)}
                key={place.halte_id}
                position={{ lat: Number(place.lat), lng: Number(place.lng) }}
                icon={{
                  url: Halte,
                  scaledSize: new  window.google.maps.Size(70, 55)
                }}
              >
                {/* Info Window Halte */}
                {pilihHalte.halte_id === place.halte_id? (
                  <InfoWindow onCloseClick={() => setPilihHalte([])} >
                    <div>
                      <h5 className='text-center'><b>Detail Halte</b></h5>
                      <table className='table'>
                        <tbody>
                          <tr>
                            <td>Nama</td>
                            <td>:</td>
                            <td>{pilihHalte.nama}</td>
                          </tr>
                          <tr>
                            <td>Alamat</td>
                            <td>:</td>
                            <td>{pilihHalte.alamat}</td>
                          </tr>
                          <tr>
                            <td>Rute</td>
                            <td>:</td>
                            <td>{pilihHalte.nama_trayek}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className='d-flex justify-content-center'>
                        <a href={`/display-halte/${pilihHalte.halte_id}`} type="button" className='p-1 btn-primary'>
                          Lihat Jadwal
                        </a>
                      </div>
                    </div>
                  </InfoWindow>
                ) : null}
              </Marker>
            );
          })) : null}

          {/* Marker Bus */}
          {bus ? (bus.map((data) => {
            return (
              <Marker
                onClick={() => onBusClick(data)}
                key={data.bus_id}
                name={data.nopol}
                position={{ lat: Number(data.lat), lng: Number(data.lng) }}
                rotation={data.rotasi}
                icon={{
                  url: Bus,
                  anchor: new window.google.maps.Point(32, 32),
                  scaledSize: new window.google.maps.Size(35, 35)
                }}
              >
                {/* Info Window Bus */}
                {pilihBus.bus_id === data.bus_id ? (
                  <InfoWindow onCloseClick={() => setPilihBus([])} >
                    <div>
                      <h5 className='text-center'><b>Detail Bus</b></h5>
                      <table className='table'>
                        <tbody>
                          <tr>
                            <td>Rute Bus</td>
                            <td>:</td>
                            <td>{pilihBus.nama}</td>
                          </tr>
                          <tr>
                            <td>Tujuan</td>
                            <td>:</td>
                            <td>{pilihBus.tujuan}</td>
                          </tr>
                          <tr>
                            <td>Estimasi Kedatangan</td>
                            <td>:</td>
                            <td>{pilihBus.jarak}<br />{pilihBus.waktu}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </InfoWindow>
                ) : null}
              </Marker>
            );
          })) : null}
      </GoogleMap>
  )
}

function getDistanceFromLatLng(lat1, lng1, lat2, lng2) {
  const earthRadius = 6371; // in km
  const dLat = deg2rad(lat2 - lat1);
  const dLng = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;
  return distance * 1000; // in meters
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function splitPolyline(polyline) {
  const segmentLength = 5; // in meters
  const path = polyline.getPath();
  const segments = [];
  let distance = 0;
  let lastSegmentEnd = path.getAt(0);
  for (let i = 1; i < path.getLength(); i++) {
    const current = path.getAt(i);
    distance += getDistanceFromLatLng(
      lastSegmentEnd.lat(),
      lastSegmentEnd.lng(),
      current.lat(),
      current.lng()
    );
    if (distance >= segmentLength) {
      const points = [];
      let segmentDistance = 0;
      while (distance >= segmentLength) {
        const ratio = (segmentLength - segmentDistance) / (distance - segmentDistance);
        const lat = lastSegmentEnd.lat() + (current.lat() - lastSegmentEnd.lat()) * ratio;
        const lng = lastSegmentEnd.lng() + (current.lng() - lastSegmentEnd.lng()) * ratio;
        points.push({ lat, lng });
        segments.push(points);
        segmentDistance += segmentLength;
      }
      distance -= segmentDistance;
      lastSegmentEnd = points[points.length - 1];
    }
  }
  if (segments.length === 0) {
    segments.push([{ lat: path.getAt(0).lat(), lng: path.getAt(0).lng() }]);
  }
  segments.push([{ lat: path.getAt(path.getLength() - 1).lat(), lng: path.getAt(path.getLength() - 1).lng() }]);
  return segments;
}

export default Contoh;