import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './App.css';
import { Context } from './assets/css-js/context';
import useFindUser from './assets/css-js/cariUser';
import PrivateRoute from './router/PrivateRoute';
import Home from "./router/Home";
import Profile from "./router/Profile";
// import Registrasi from './router/Registrasi';
import Login from "./router/Login";
import LupaPassword from './router/LupaPassword';
import ResetPassword from './router/ResetPassword';
import Log from './router/Log';
import Jadwal from './router/Jadwal';
import Tracking from './router/Tracking';
import ListHalte from './router/ListHalte';
import ListBus from './router/ListBus';
import ListUser from './router/ListUser';
import ListUserGroup from './router/ListUserGroup';
import ListTrayek from './router/ListTrayek';
import DetailHalte from './router/DetailHalte';
import ListDisplay from './router/ListDisplay';
import DisplayHalte from './router/DisplayHalte';
import PrintJadwal from './router/PrintJadwal';
import PrintDetail from './router/PrintDetail';
import Beranda from './public/website/Beranda';
import Rute from './public/website/Rute';
import Kontak from './public/website/Kontak';
import SettingIp from './router/Setting';
import Contoh from './public/template/contoh';

function App() {
  const { user, setUser, isLoading } = useFindUser();
  return (
    <BrowserRouter>
      <Context.Provider value={{ user, setUser, isLoading }}>
        <Routes>
          {/* Umum */}
          <Route path="/" element={<Beranda />} />,
          <Route path="/rute" element={<Rute />} />,
          <Route path="/kontak" element={<Kontak />} />,
          <Route path="/detail-halte/:halte_id" element={<DetailHalte />} />,
          <Route path="/contoh" element={<Contoh />} />,
          {/* Admin */}
          <Route exact path='/' element={<PrivateRoute />}>
            <Route exact path="/dashboard" element={<Home />} />,
            <Route path="/profile/:user_id" element={<Profile />} />,
            <Route path="/log-aktivitas" element={<Log />} />,
            <Route path="/jadwal" element={<Jadwal />} />,
            <Route path="/print-jadwal" element={<PrintJadwal />} />,
            <Route path="/tracking" element={<Tracking />} />,
            <Route path="/print-detail/:halte_id" element={<PrintDetail />} />,
            <Route path="/list-halte" element={<ListHalte />} />,
            <Route path="/list-display" element={<ListDisplay />} />,
            <Route path="/display-halte/:halte_id" element={<DisplayHalte />} />,
            <Route path="/list-bus" element={<ListBus />} />,
            <Route path="/list-user" element={<ListUser />} />,
            <Route path="/list-grup" element={<ListUserGroup />} />,
            <Route path="/list-trayek" element={<ListTrayek />} />,
            <Route path="/setting" element={<SettingIp />} />,
          </Route>
          <Route path="/Login" element={<Login />} />,
          {/* <Route path="/Registrasi" element={<Registrasi />} />, */}
          <Route path="/LupaPassword" element={<LupaPassword />} />,
          <Route path="/ResetPassword" element={<ResetPassword />} />,
        </Routes>
      </Context.Provider>
    </BrowserRouter>
  )
}

export default App;
