import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import InfoWindowEx from '../../assets/css-js/InfoWindow';
import Icon from '../../assets/image/halte2.png';
import Bus from '../../assets/image/bus-tracker.png';
import { ukuran, customizeMap, warna } from '../../assets/css-js/TemplateMap';

const rad = function (x) {
  return x * Math.PI / 180;
};

class TrackingMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      pilihHalte: {},
      markerBus: false,
      showingInfoBus: false,
      activeBus: {},
      pilihBus: {},
      labelBus: {},
      tracker: {},
    };
  }
  componentDidMount() {
    this.connect_socket();
  }

  // menampilkan informasi halte
  onMarkerClick = (props, marker, e) => {
    this.setState({
      pilihHalte: props.place_,
      activeMarker: marker,
      showingInfoWindow: true
    });
  };

  // menampilkan informasi bus
  onBusClick = (props, marker) => {
    this.setState({
      pilihBus: props.places,
      activeBus: marker,
      showingInfoBus: true
    });
  };

  // konek ke websocket
  connect_socket = () => {
    const changePosisi = (d) => {
      this.setState({
        tracker: { lat: d.lat, lng: d.lng }
      });
      console.log("posisi berubah")
    }
    // hitung jarak 2 marker dalam meter
    const getDistance = function (p1, p2) {
      var R = 6378137;
      var dLat = rad(p2.lat() - p1.lat());
      var dLong = rad(p2.lng() - p1.lng());
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    };

    // ada pesan masuk ke websocket
    let sc = new WebSocket(this.props.ip);
    sc.onmessage = function (ev) {
      console.log('ws message')
      try {
        var d = JSON.parse(ev.data);
        console.table(d);
        changePosisi(d);
        const data = { lat: d.lat, lng: d.lng }
        getDistance(this.props.lokasi, data)
      } catch (e) {
        console.log("coba lagi");
      }
    }
    sc.onopen = function (ev) {
      console.log('ws openned');
    }
    sc.onerror = function (ev) {
      console.log('ws error');
      console.log(ev);
      sc.close();
    }
    sc.onclose = function (ev) {
      console.log('ws closed');
      console.log(ev);
      setTimeout(function () {
        this.connect_socket();
      }, 1000);
    }
  }

  // jika map sudah siap
  onMapReady = (mapProps, map) => {
    this.props.trayek.map((data, index) => {
      const lat_array = data.lat.split(',');
      const lng_array = data.lng.split(',');
      let wayPoints = [];
      //setting jalur bus
      var rendererOptions = {
        map: map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: warna[index]
        }
      }

      //panggil fungsi directions service dan render
      const directionsService = new window.google.maps.DirectionsService();
      const directionsDisplay = new window.google.maps.DirectionsRenderer(rendererOptions);

      //ambil data trayek dan masukkan di fungsi render
      directionsDisplay.setMap(map);

      //ambil data awal jalur
      let start = { lat: Number(lat_array[0]), lng: Number(lng_array[0]) };

      //ambil data akhir jalur
      let end = {
        lat: Number(lat_array[lat_array.length - 1]),
        lng: Number(lng_array[lng_array.length - 1]),
      };

      //ambil semua jalur pemberhentian
      for (let i = 1; i < lng_array.length - 1; i++) {
        wayPoints.push({
          location: { lat: Number(lat_array[i]), lng: Number(lng_array[i]) }
        });
      }

      // jalur pemberhentian
      let request = {
        origin: start,
        waypoints: wayPoints,
        destination: end,
        travelMode: "DRIVING",
      };

      //menampilkan data directionsrenderer
      directionsService.route(request, function (result, status) {
        if (status === window.google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(result);
        }
      });
      return 'oke';
    })
  };

  trackerBus = (mapProps, map) => {
    var directionsRenderer = new window.google.maps.DirectionsRenderer();
    var directionsService = new window.google.maps.DirectionsService();
    directionsRenderer.setMap(map);
    directionsRenderer.setPanel(document.getElementById("sidebar"));
    // var control = document.getElementById("floating-panel");
    // map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(control);
    this.calculateAndDisplayRoute(directionsService, directionsRenderer);
  }

  // hitung jarak
  calculateAndDisplayRoute = (directionsService, directionsRenderer) => {
    //ambil data awal jalur
    let start = { lat: Number(this.props.places[0]), lng: Number(this.props.places[0]) };

    //ambil data akhir jalur
    let end = {
      lat: Number(this.props.places[this.props.places.length - 1]),
      lng: Number(this.props.places[this.props.places.length - 1]),
    };
    directionsService
      .route({
        origin: start,
        destination: end,
        travelMode: window.google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(Date.now() + 10000),
          trafficModel: 'optimistic'
        }
      })
      .then(function (response) {
        directionsRenderer.setDirections(response);
      })
      .catch(function (e) { console.log("Directions request failed due to " + e.status); });
  }

  render() {
    const { google } = this.props;
    return (
      <div className='col-md-12'>
        <Map
          google={this.props.google}
          styles={customizeMap}
          style={ukuran}
          zoom={15}
          onReady={this.onMapReady}
          initialCenter={this.props.lokasi}>

          {/* Marker Halte */}
          {this.props.places ? (this.props.places.map((place) => {
            return (
              <Marker
                onClick={this.onMarkerClick}
                key={place.halte_id}
                place_={place}
                position={{ lat: place.lat, lng: place.lng }}
                icon={{
                  url: Icon,
                  scaledSize: new this.props.google.maps.Size(70, 55)
                }}
              />
            );
          })) : null}


          {/* Marker Bus */}
          {this.props.bus ? (this.props.bus.map((data) => {
            return (
              <Marker
                onClick={this.onBusClick}
                key={data.bus_id}
                places={data}
                name={data.nopol}
                position={{ lat: this.state.tracker.lat, lng: this.state.tracker.lng }}
                icon={{
                  url: Bus,
                  anchor: new google.maps.Point(32, 32),
                  scaledSize: new google.maps.Size(35, 35)
                }}
              />
            );
          })) : null}


          {/* Detail Halte */}
          <InfoWindowEx
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
            <div>
              <h5 className='text-center'><b>Detail Halte</b></h5>
              <table className='table'>
                <tbody>
                  <tr>
                    <td>Nama</td>
                    <td>:</td>
                    <td>{this.state.pilihHalte.nama}</td>
                  </tr>
                  <tr>
                    <td>Alamat</td>
                    <td>:</td>
                    <td>{this.state.pilihHalte.alamat}</td>
                  </tr>
                  <tr>
                    <td>Rute</td>
                    <td>:</td>
                    <td>{this.state.pilihHalte.nama_trayek}</td>
                  </tr>
                </tbody>
              </table>
              <div className='d-flex justify-content-center'>
                <a href={`/detail-halte/${this.state.pilihHalte.halte_id}`} type="button" className='p-1 btn-primary'>
                  Lihat Detail
                </a>
              </div>
            </div>
          </InfoWindowEx>


          {/* Detail Bus */}
          <InfoWindowEx
            marker={this.state.activeBus}
            visible={this.state.showingInfoBus}>
            <div>
              <h5 className='text-center'><b>Detail Bus</b></h5>
              <table className='table'>
                <tbody>
                  <tr>
                    <td>Rute Bus</td>
                    <td>:</td>
                    <td>{this.state.pilihBus.nama}</td>
                  </tr>
                  <tr>
                    <td>Tujuan</td>
                    <td>:</td>
                    <td>{this.state.tracker.tujuan}</td>
                  </tr>
                  <tr>
                    <td>Estimasi Kedatangan</td>
                    <td>:</td>
                    <td>{this.state.tracker.estimasi}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </InfoWindowEx>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCnTyLMTagAytZaQfW7pcZTFAd1p3NE7Bc'
})(TrackingMap);