import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tgl } from '../template/waktu';
import Maps from '../template/map';
import axios from 'axios';
import QRCode from "react-qr-code";
import Geocode from "react-geocode";

function PrintDetail() {
  const { halte_id } = useParams();
  const [Halte, setHalte] = useState([]);
  const [Bus, setBus] = useState([]);
  const [pageURL, setPageURL] = useState(0);
  const [Alamat, setAlamat] = useState([]);
  useEffect(() => {
    setPageURL(window.location.href);
    getHalteById();
    getBus();
  }, []);

  // ambil data halte berdasarkan id
  const getHalteById = async () => {
    const response = await axios.get(`/trayek_halte/halte/${halte_id}`);
    setHalte(response.data[0]);
    Geocode.setApiKey("AIzaSyCnTyLMTagAytZaQfW7pcZTFAd1p3NE7Bc");
    // dapatkan alamat dari koordinat
    Geocode.fromLatLng(response.data[0].lat, response.data[0].lng).then(
      (response) => {
        const alamat = response.results[0].formatted_address;
        setAlamat(alamat);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  // ambil data bus
  const getBus = async () => {
    const response = await axios.get('/bus');
    setBus(response.data);
  }

  return (
    <div className='container'>
      {/* <buton id="x" onClick={print} className="btn btn-success"><i className="fas fa-print"></i> Cetak</buton> */}
      <table className='print-table'>
        <thead>
          <tr className='garis-bawah'>
            <th className="text-center"><img src={require(`../../assets/image/ptis.png`)} alt="logo" className='print-logo' /></th>
            <th className="text-center spasi">
              <h3 className='text-bold spasi'>Public Transport Information System</h3>
              <h5 className='text-bold spasi'>Laporan Data Halte {Halte.nama}</h5>
              <h6>Jl. Elang Jawa No.12, Wedomartani, Kec. Ngemplak, Kab. Sleman, D.I. Yogyakarta (55584)</h6>
            </th>
            <th className="text-center"><img src={require(`../../assets/image/javis.png`)} alt="logo" className='print-javis' /></th>
            <th className="text-center"><img src={require(`../../assets/image/dishub.png`)} alt="logo" className='print-javis' /></th>
          </tr>
        </thead>
      </table>
      <div className='row'>
        <div>
          <p className='float-end'> <b>Waktu Cetak Dokumen </b> : <Tgl /></p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 ">
          <table className="table">
            <tbody>
              <tr>
                <th>Nama Halte</th>
                <td>:</td>
                <td>{Halte.nama}</td>
              </tr>
              <tr>
                <th>Trayek</th>
                <td>:</td>
                <td>{Halte.nama_trayek}</td>
              </tr>
              <tr>
                <th>Koordinat</th>
                <td>:</td>
                <th>{Halte.lat}<br />{Halte.lng}</th>
              </tr>
              <tr>
                <th>Alamat</th>
                <td>:</td>
                <td>{Alamat}</td>
              </tr>
              <tr>
                <th>Lokasi</th>
                <td>:</td>
                <th><div style={{ width: '250px', minHeight: '180px' }}><Maps halte={Halte} /></div></th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <h4 className='text-center text-bold'>Laporan Jadwal Halte</h4>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>No.</th>
                <th>Trayek Bus</th>
                <th>Nomor Kendaraan</th>
                <th>Halte Tujuan</th>
                <th>Jarak Estimasi</th>
              </tr>
            </thead>
            <tbody>
              {Bus ? (Bus.map((data, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{data.nama}</td>
                    <td>{data.nopol}</td>
                    <td>{data.tujuan}</td>
                    <td>
                      {data.jarak} <br />
                      {data.waktu}
                    </td>
                  </tr>
                )
              })) : (null)}
            </tbody>
          </table>
        </div>
      </div>

      {/* Footer */}
      <hr className="mt-3" />
      <div className="row">
        <div className="col-6 col-sm-8 col-md-10">
          <h6>Copyright © PT. Javis Teknologi Albarokah</h6>
          <p className="ctext_10"><b>Cetak Dokumen </b> : <Tgl /></p>
        </div>
        <div className="col-6 col-sm-4 col-md-2 pe-3 pb-3 d-flex justify-content-end">
          <QRCode
            size={100}
            style={{ height: "auto", maxWidth: "100%" }}
            value={pageURL}
          />
        </div>
      </div>
    </div>
  )
}

export default PrintDetail;