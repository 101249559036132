import React from 'react';
import Header from '../component/template/Header';
import Activity from '../component/log/log';
import Footer from '../component/template/Footer';

function Log() {
  return (
    <div>
      <Header />
      <div className="content">
        <div className="tinggi-content">
        <Activity />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Log;