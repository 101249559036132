import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import InfoWindowEx from '../../assets/css-js/InfoWindow';
import IconBus from '../../assets/image/bus-tracker.png';
import IconHalte from '../../assets/image/halte2.png';

const customizeMap = [
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];
const ukuran = {
  position: "relative",
  width: "100%",
  height: "100%"
};

class JadwalMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      pilihHalte: {},
      markerBus: false,
      showingInfoBus: false,
      activeBus: {},
      pilihBus: {},
      halte: [],
    }
  }

  componentDidMount() {
    this.getHalte();
  }

  // menampilkan informasi halte
  onHalteClick = (props, marker, e) => {
    this.setState({
      pilihHalte: props.place_,
      activeMarker: marker,
      showingInfoWindow: true
    });
  };

  // menampilkan informasi bus
  onBusClick = (props, marker) => {
    this.setState({
      pilihBus: props.places,
      activeBus: marker,
      showingInfoBus: true
    });
  };

  // ambil data halte berdasarkan trayek
  getHalte = () => {
    this.props.trayek.map((data, index) => {
      const lat_array = data.lat.split(',');
      const lng_array = data.lng.split(',');
      const nama_halte = data.nama_halte.split(',');
      const halte_id = data.halte_id.split(',');
      for (let i = 0; i < halte_id.length; i++) {
        const halteArray = {
          halte_id: halte_id[i],
          nama: nama_halte[i],
          lat: lat_array[i],
          lng: lng_array[i]
        }
        this.setState(dataHalte => ({
          halte: [...dataHalte.halte, halteArray]
        }))
      }
      return 'oke'
    });
  }

  // jika map sudah siap
  onMapReady = (mapProps, map) => {
    this.props.trayek.map((data, index) => {
      const lat_array = data.lat.split(',');
      const lng_array = data.lng.split(',');
      let wayPoints = [];
      //setting jalur bus
      var rendererOptions = {
        map: map,
        suppressMarkers: true,
      }

      //panggil fungsi directions service dan render
      const directionsService = new window.google.maps.DirectionsService();
      const directionsDisplay = new window.google.maps.DirectionsRenderer(rendererOptions);

      //ambil data trayek dan masukkan di fungsi render
      directionsDisplay.setMap(map);

      //ambil data awal jalur
      let start = { lat: Number(lat_array[0]), lng: Number(lng_array[0]) };

      //ambil data akhir jalur
      let end = {
        lat: Number(lat_array[lat_array.length - 1]),
        lng: Number(lng_array[lng_array.length - 1]),
      };

      //ambil semua jalur pemberhentian
      for (let i = 1; i < lng_array.length - 1; i++) {
        wayPoints.push({
          location: { lat: Number(lat_array[i]), lng: Number(lng_array[i]) }
        });
      }

      // jalur pemberhentian
      let request = {
        origin: start,
        waypoints: wayPoints,
        destination: end,
        travelMode: "DRIVING",
      };

      //menampilkan data directionsrenderer
      directionsService.route(request, function (result, status) {
        if (status === window.google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(result);
        }
      });
      return 'oke';
    })
  };

  render() {
    const { google } = this.props;
    return (
      <Map
        google={this.props.google}
        styles={customizeMap}
        style={ukuran}
        zoom={15}
        onReady={this.onMapReady}
        initialCenter={{ lat: this.props.bus.lat, lng: this.props.bus.lng }}>

        {/* Marker Halte */}
        {this.state.halte ? (this.state.halte.map((place) => {
          return (
            <Marker
              onClick={this.onMarkerClick}
              key={place.halte_id}
              place_={place}
              position={{ lat: place.lat, lng: place.lng }}
              icon={{
                url: IconHalte,
                scaledSize: new this.props.google.maps.Size(70, 55)
              }}
            />
          );
        })) : null}

        {/* Marker Bus */}
        {this.props.bus ? (
          <Marker
            onClick={this.onBusClick}
            key={this.props.bus.bus_id}
            places={this.props.bus}
            name={this.props.bus.nopol}
            position={{ lat: this.props.bus.lat, lng: this.props.bus.lng }}
            animation="drop"
            rotation={this.props.bus.rotasi}
            icon={{
              url: IconBus,
              anchor: new google.maps.Point(32, 32),
              scaledSize: new google.maps.Size(35, 35)
            }}
          />
        ) : null}

        {/* Detail Halte */}
        <InfoWindowEx
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}>
          <div>
            <h5 className='text-center'><b>Detail Halte</b></h5>
            <table className='table'>
              <tbody>
                <tr>
                  <td>Nama</td>
                  <td>:</td>
                  <td>{this.state.pilihHalte.nama}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>:</td>
                  <td>{this.state.pilihHalte.alamat}</td>
                </tr>
                <tr>
                  <td>Rute</td>
                  <td>:</td>
                  <td>{this.state.pilihHalte.nama_trayek}</td>
                </tr>
              </tbody>
            </table>
            <div className='d-flex justify-content-center'>
              <a href={`/detail-halte/${this.state.pilihHalte.halte_id}`} type="button" className='p-1 btn-primary'>
                Lihat Detail
              </a>
            </div>
          </div>
        </InfoWindowEx>


        {/* Detail Bus */}
        <InfoWindowEx
          marker={this.state.activeBus}
          visible={this.state.showingInfoBus}>
          <div>
            <h5 className='text-center'><b>Detail Bus</b></h5>
            <table className='table'>
              <tbody>
                <tr>
                  <td>Rute Bus</td>
                  <td>:</td>
                  <td>{this.state.pilihBus.nama}</td>
                </tr>
                <tr>
                  <td>Tujuan</td>
                  <td>:</td>
                  <td>{this.state.pilihBus.tujuan}</td>
                </tr>
                <tr>
                  <td>Estimasi Kedatangan</td>
                  <td>:</td>
                  <td>{this.state.pilihBus.waktu}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </InfoWindowEx>

      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCnTyLMTagAytZaQfW7pcZTFAd1p3NE7Bc'
})(JadwalMap);